import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import morefilters from "../../../../webroot/images/morefiltersicon.png";
import "react-datepicker/dist/react-datepicker.css";
import { MasterService } from "../../../../services";
import { toast } from "react-toastify";

const CATEGORY_OPTIONS = [
  { id: "new", label: "New", value: "new" },
  { id: "active", label: "Active", value: "active" },
  { id: "super_active", label: "Super Active", value: "super_active" },
  { id: "churn", label: "Churn", value: "churn" },
  { id: "irregular", label: "Irregular", value: "irregular" },
  { id: "inactive", label: "Inactive", value: "inactive" },
];

const FOLLOW_UP_OPTIONS = [
  { id: "overdue", label: "Overdue", value: "overdue" },
  { id: "today", label: "Today", value: "today" },
  { id: "upcoming", label: "Upcoming", value: "upcoming" },
  { id: "unattempted", label: "Unattempted", value: "unattempted" },
];

class SearchLeadDealer extends Component {
  constructor(props) {
    super(props);
    this.handleChangetext = this.handleChangetext.bind(this);
    this.state = {
      filterData: {},
      callSubStatus: [],
    };
  }

  getCallSubStatus = async (statusId) => {
    try {
      const response = await MasterService.get(
        `/partner/partner-call/sub-status?status_id=${statusId}&source=sales`
      );
      this.setState({ callSubStatus: response.data.data });
    } catch (error) {
      console.error("Error fetching sub-status list:", error);
      toast.error("Error fetching sub-status list");
    }
  }

  handleOutsideClick = (event) => {
    const toggleCheckbox = document.getElementById('toggleCheckbox');
    if (toggleCheckbox.checked) {
      toggleCheckbox.checked = false;
    }
};

  handleChangetext = (event) => {
    
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    if (sname === "subsource_id"){
      filterData[sname] = ovalue.id != null ? ovalue.id : ovalue.id;
    }
    else if (sname === "profession_id" || sname === "assigned_bm_id" || sname === "kyc" || sname === "status_id" || sname === "source") {
      filterData[sname] = (ovalue.id != null) ? ovalue.id : ovalue.id;
    } 
    else if (sname === "call_status") {
      this.getCallSubStatus(ovalue.status_id);
      filterData[sname] = (ovalue.status_id != null) ? ovalue.status_id :"";
    } else if (sname === "call_sub_status") {
      filterData[sname] = (ovalue.sub_status_id != null) ? ovalue.sub_status_id : null;
    } else {
      filterData[sname] = (ovalue.value != null) ? ovalue.value : ovalue.target.value;
    }
    this.setState({ filterData });
    if (typeof this.props.optionChange === 'function') {
      this.props.optionChange(sname, ovalue);
    }

  }



  handleCityOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = ovalue.id != null ? ovalue.id : ovalue.target.id;
    this.setState({ filterData });
    if (typeof this.props.optionCityChange === "function") {
      this.props.optionCityChange(sname, ovalue);
    }
  };
  handleSkuOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] =
      ovalue.sku_short_name != null
        ? ovalue.sku_short_name
        : ovalue.target.sku_short_name;
    this.setState({ filterData });
    if (typeof this.props.optionSkuChange === "function") {
      this.props.optionSkuChange(sname, ovalue);
    }
  };

  handlePremiumChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = ovalue.code != null ? ovalue.code : ovalue.target.code;
    this.setState({ filterData });
    if (typeof this.props.optionPremiumChange === "function") {
      this.props.optionPremiumChange(sname, ovalue);
    }
  };

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      this.setState({ redirect: true });
    }
  }
  submitFilterForm = (event) => {
    event.preventDefault();
    if (typeof this.props.onSubmitFilter === "function") {
      this.props.onSubmitFilter(event);
      const toggleCheckbox = document.getElementById("toggleCheckbox");
      toggleCheckbox.checked = false;
    }
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps &&
      nextProps.searchByList &&
      nextProps.searchByList[0] &&
      !this.state.filterData.search_by
    ) {
      this.handleOptionChange("search_by", {
        value: nextProps.searchByList[0].value,
      });
    }
  }

componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
}

componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
}

handleClickOutside = (event) => {
    const moreFilterDiv = document.querySelector(".more-filter");
    if (!moreFilterDiv?.contains(event.target)) {
        const toggleCheckbox = document.getElementById("toggleCheckbox");
        if (toggleCheckbox) toggleCheckbox.checked = false;
    }
}

  resetFilterForm = (event) => {
    window.location.reload(false);
  };
  render() {
    const {
      searchByList,
      cityAllList,
      subSource,
      status,
      source,
      callStatus
    } = this.props;
    const { filterData } = this.state;
    return (
      <>
        <form onSubmit={this.submitFilterForm}>
          <div className="top-heading">
          
            <div  className="filterflex">
            <h2 className="top-heading">Pre Register Lead Manager</h2>
              <div className="filterinput">
             
                <div>
                  <fieldset className="single-select">
                    <div className="material">
                      <Select
                        styles={customStyles}
                        onChange={this.handleOptionChange.bind(
                          this,
                          "search_by"
                        )}
                        options={searchByList}
                        name="search_by"
                        placeholder={this.props.t(
                          "Search By"
                        )}
                        value={searchByList.filter(
                          ({ value }) => value === filterData.search_by
                        )}
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ value }) => value}
                      />
                    </div>
                  </fieldset>
                </div>

          <div>
              <fieldset className="search-bx">
                <div className="material">
                  <input
                    id=""
                    type="text"
                    name="search_text"
                    
                    onChange={this.handleOptionChange.bind(
                      this,
                      "search_text"
                    )}
                    className="form-input-new"
                    title={"SEARCH_TEXT"}
                    placeholder="Enter Keyword"
                  />
                  <i className="ic-search"></i>
                </div>
              </fieldset>
            </div>
              </div>
            </div>
          </div>
          <div className="loan-filter-bg">
           <div className="top-filter-bg">
          <div className="form-tab-right-panel">
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <label>Select City</label>
              <Select
                styles={customStyles}
                id="city_id"
                //  value={filterData.city_id}
                onChange={this.handleCityOptionChange.bind(this, 'city_id')}
                options={cityAllList && cityAllList.length > 0 ? cityAllList : []}
                name="city_id"
                placeholder="Select City"
                value={cityAllList && cityAllList.length > 0 && cityAllList.filter(({ id }) => id === filterData.city_id)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
              />
            </div>
              
                  <div className="col-sm-2">
                    <fieldset className="multiselect-dropDown">
                      <div className="material">
                        <label>Source</label>
                          <Select
                            styles={customStyles}
                            id="source"
                            options={source}
                            onChange={this.handleOptionChange.bind(
                              this,
                              "source"
                            )}
                            name="source"
                            placeholder="Source*"
                            value={
                              source &&
                              source.length > 0 &&
                              source.filter(
                                ({ id }) => id === filterData.source
                              )
                            }
                            getOptionLabel={({ lable }) => lable}
                          />
                      </div>
                    </fieldset>
                  </div>
                    
                  <div className="col-sm-2">
                    <fieldset className="multiselect-dropDown">
                      <div className="material">
                        <label>Sub Source</label>
                          <Select
                            styles={customStyles}
                            id="subsource_id"
                            options={subSource}
                            onChange={this.handleOptionChange.bind(
                              this,
                              "subsource_id"
                            )}
                            name="subsource_id"
                            placeholder="Sub Source*"
                            value={
                              subSource &&
                              subSource.length > 0 &&
                              subSource.filter(
                                ({ id }) => id === filterData.subsource_id
                              )
                            }
                            getOptionLabel={({ subsource }) => subsource}
                          />
                      </div>
                    </fieldset>
                  </div>


                  <div className="col-sm-2">
        <fieldset className="multiselect-dropDown">
          <div className="material">
            <label>Call Status</label>
            <Select
              styles={customStyles}
              id="call_status"
              options={callStatus}
              onChange={this.handleOptionChange.bind(
                this,
                "call_status"
              )}
              name="call_status"
              placeholder="Call Status*"
              value={
                callStatus &&
                callStatus.length > 0 &&
                callStatus.find(
                  (option) => option.status_id === filterData.status_id
                )
              }
              getOptionLabel={({ status_name }) => status_name}
              getOptionValue={({ status_id }) => status_id}
            />
          </div>
        </fieldset>

    
      </div>
 
                  <div className="col-sm-2">
                    <fieldset className="multiselect-dropDown">
                      <div className="material">
                        <label>Call Sub Status</label>
                          <Select
                            styles={customStyles}
                            id="call_sub_status"
                            options={this.state.callSubStatus}
                            onChange={this.handleOptionChange.bind(
                              this,
                              "call_sub_status"
                            )}
                            name="call_sub_status"
                            placeholder="Call Sub Status*"
                            value={
                              this.state.callSubStatus &&
                              this.state.callSubStatus.length > 0 &&
                              this.state.callSubStatus.filter(
                                ({ sub_status_id }) => sub_status_id === filterData.call_sub_status
                              )
                            }
                            getOptionLabel={({ sub_status_name }) => sub_status_name}
                            getOptionValue={({ sub_status_id }) => sub_status_id}
                          />
                      </div>
                    </fieldset>
                  </div>
                  
                  <div className="col-sm-2">
                    <fieldset className="multiselect-dropDown">
                      <div className="material">
                        <label>Verification Status</label>
                          <Select
                            styles={customStyles}
                            id="status_id"
                            options={status}
                            onChange={this.handleOptionChange.bind(
                              this,
                              "status_id"
                            )}
                            name="status_id"
                            placeholder="Verification Status*"
                            value={
                              status &&
                              status.length > 0 &&
                              status.filter(
                                ({ id }) => id === filterData.status_id
                              )
                            }
                            getOptionLabel={({ lable }) => lable}
                          />
                      </div>
                    </fieldset>
                  </div>
                  
                  <div className="col-sm-2">
                    <fieldset className="multiselect-dropDown">
                      <div className="material">
                        <label>Category</label>
                          <Select
                            styles={customStyles}
                            id="category"
                            options={CATEGORY_OPTIONS}
                            onChange={this.handleOptionChange.bind(
                              this,
                              "category"
                            )}
                            name="category"
                            placeholder="Category"
                            value={
                              CATEGORY_OPTIONS.filter(
                                ({ value }) => value === filterData.category
                              )
                            }
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                          />
                      </div>
                    </fieldset>
                  </div>
                  
                  <div className="col-sm-2">
                    <fieldset className="multiselect-dropDown">
                      <div className="material">
                        <label>Followup Category</label>
                          <Select
                            styles={customStyles}
                            id="followup_category"
                            options={FOLLOW_UP_OPTIONS}
                            onChange={this.handleOptionChange.bind(
                              this,
                              "followup_category"
                            )}
                            name="followup_category"
                            placeholder="Followup Category"
                            value={
                              FOLLOW_UP_OPTIONS.filter(
                                ({ value }) => value === filterData.followup_category
                              )
                            }
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ value }) => value}
                          />
                      </div>
                    </fieldset>
                  </div>

          <div className="col-sm-2">
            <div className="more-filter" style={{marginTop:"20px"}} >
              <label className="morefilterbtn" htmlFor="toggleCheckbox">
                More filter
                <img src={morefilters} style={{marginLeft:5}} alt='icon' width={20} height={20} />
              </label>
              <input type="checkbox" id="toggleCheckbox" />
              <div id="filterCard">
                <div>

<div className="col-sm-12">
                    <label>From Follow-Up Date</label>
                    <div >
                      <InputField
                        inputProps={{
                          id: "from_follow_up_date",
                          type: "date",
                          name: "from_follow_up_date",
                          autocompleate: "off",
                        }}
                        onChange={this.handleOptionChange.bind(this, 'from_follow_up_date')}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <label>To Follow-Up Date</label>
                    <div >
                      <InputField
                        inputProps={{
                          id: "to_follow_up_date",
                          type: "date",
                          name: "to_follow_up_date",
                          autocompleate: "off",
                        }}
                        onChange={this.handleOptionChange.bind(this, 'to_follow_up_date')}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <label>From Created Date</label>
                    <div >
                      <InputField
                        inputProps={{
                          id: "from_date",
                          type: "date",
                          name: "from_date",
                          autocompleate: "off",
                        }}
                        onChange={this.handleOptionChange.bind(this, 'from_date')}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <label>To Created Date</label>
                    <div >
                      <InputField
                        inputProps={{
                          id: "to_date",
                          type: "date",
                          name: "to_date",
                          autocompleate: "off",
                        }}
                        onChange={this.handleOptionChange.bind(this, 'to_date')}
                      />
                    </div>
                  </div>
                   <div className="col-sm-12">
                  <InputField
                        inputProps={{
                          id: "dealer_updated_date_from",
                          type: "date",
                          name: "dealer_updated_date_from",
                          autocompleate: "off",
                          label: ('From Updated Date'),

                        }}
                        onChange={this.handleOptionChange.bind(this, 'dealer_updated_date_from')}
                      />
                 </div>
                  
                  <div className="col-sm-12">
                      <InputField
                        inputProps={{
                          id: "dealer_updated_date_to",
                          type: "date",
                          name: "dealer_updated_date_to",
                          autocompleate: "off",
                          label: ('To Update Date'),

                        }}
                        onChange={this.handleOptionChange.bind(this, 'dealer_updated_date_to')}
                      />
                    </div>
                 
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-2" style={{marginTop: "20px"}}>
                <div className="more-filter">
                  <div className="filter-option">
               
                      <div className="text-btn d-flex">
                        <div className="btn-submit-reset top-btn-none pull-right">
                          <button className="btn-primary">
                            Search
                          </button>
                          <button
                            onClick={this.resetFilterForm}
                            className="btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>

          </div>
          </div>
          </div>
          </div>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'white' : state.isFocused ? '#2684ff' : 'white',
    color: 'black',
  }),
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};
export default withTranslation("dealers")(
  connect(mapStateToProps, mapDispatchToProps)(SearchLeadDealer)
);
