import React, { useEffect, useState, useMemo } from "react";
import Formsy, { addValidationRule } from "formsy-react";
import FormsyInputField from "../../../elements/FormsyInputField";
import { useLocation } from "react-router-dom";
import MasterService from "../../../../services/MasterService";
import Select from "react-select";
import secureStorage from "../../../../config/partnerEncrypt";
import { toast } from "react-toastify";
import VerifyOtpModal from "../components/verify-otp-popup";
import Modal from "../../../elements/partnerModal";
import { IS_USER_ACCESS_PAGE } from "../../../../config/constants";
import { DealerService } from "../../../../services";
import Modal1 from "../../component/modal/modal";
import LoggedinPopup from "../../../elements/LoggedinPopup";
import PartnerWhatsAppChat from "../../PartnerWhatsAppChat";
import PreLeadActivity from "./components/lead-activity-history.component";
import createActivityLog, {
  lostLeadActivity,
  preLeadFormActivity,
  registerAsPartner,
} from "../../../../services/preLeadActivityService";
import { getStatusUtility } from "../utility/getStatusUtility";
import {
  CHAT_TEMPLATE_LIST,
  CHAT_LIST,
  GET_UNREAD_MESSAGE_COUNT,
} from "../../../../services/leads.gql";
import CallDetailCard from "./components/call-detail-card";

import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { getCurrentUserDetails } from "../utility/getUserInfo";
import {
  formatDate,
  getDateAndTimeFormat,
} from "../utility/dateFormateUtility";
import NewCallDetailCard from "./components/new-call-detail-card ";
import PartnerCallHistory from "./components/call-history.component";
import Loader from "../../../elements/Loader";
import generalService from "../../../../services/generalService";
import callicon from "../../../../webroot/images/callicon.svg";

const PartnerLeadDetailsPage = () => {
  const { state: { lead, status } = {} } = useLocation();
  const [leadData, setLeadData] = useState({});
  const [formData, setFormData] = useState({});
  const [getSubSource, setgetSubSource] = useState([]);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reject, setReject] = useState(false);
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [prevFormData, setPreviousFormData] = useState({});
  const [unReadMessage, SetUnReadMessage] = useState();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isLodingMsg, setIsLodingMsg] = useState(false);
  const [showCallActivityModal, setShowCallActivityModal] = useState(false);
  const [cityList, setCityList] = useState([]);

  const access = useMemo(
    () => ({
      partner_lead_history: IS_USER_ACCESS_PAGE(
        "partner",
        "partner_lead_history"
      )?.is_edit_access,
      comms_status: IS_USER_ACCESS_PAGE("partner", "comms_status")
        ?.is_edit_access,
      register_as_partner: IS_USER_ACCESS_PAGE("partner", "register_as_partner")
        ?.is_edit_access,
      mark_lost: IS_USER_ACCESS_PAGE("partner", "mark_lost")?.is_edit_access,
      // two_way_communication: IS_USER_ACCESS_PAGE(
      //   "partner",
      //   "two_way_communication"
      // )?.is_edit_access,
      save: IS_USER_ACCESS_PAGE("partner", "save")?.is_edit_access,
      click_to_call_sales: IS_USER_ACCESS_PAGE("click_to_call_sales", "click_to_call_sales")?.is_edit_access,
    }),
    []
  );

  let two_way_communication = IS_USER_ACCESS_PAGE(
    "partner",
    "two_way_communication"
  )?.is_edit_access;
  const rejectReasons = [
    { id: 1, reason: "Not Interested" },
    { id: 2, reason: "Already working with other DSA's" },
    { id: 3, reason: "Is not a relevant lead they do not do Home loans" },
  ];

  useEffect(() => {
    fetchData();
    getCityList();
  }, []);

  const getCityList = async () => {
    let response = await generalService.stateCityList();
    const city = response.data.data.city;
    setCityList(city);
  };

  const getUnreadMessage = (mobileNumber) => {
    setIsLodingMsg(true);
    executeGraphQLQuery(GET_UNREAD_MESSAGE_COUNT(mobileNumber), client)
      .then((response) => {
        if (response?.data?.unread_message_list.length > 0) {
          SetUnReadMessage(response?.data?.unread_message_list[0]);
          setIsLodingMsg(false);
        } else {
          setIsLodingMsg(false);
        }
      })
      .catch((error) => {
        console.error("GraphQL error:", error);
        setIsLodingMsg(false);
      });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const startTime = new Date().getTime();
      const dealerHashId = window.location.pathname.split("/")[2];
      const [{ data: subSourceData }, { data: leadData }] = await Promise.all([
        MasterService.get("/partner/partner/get-sub-source"),
        DealerService.getBasicDetails({
          dealer_id_hash: dealerHashId,
          _with: ["DIF"],
        }),
      ]);
      const endTime = new Date().getTime();
      // Calculate the duration
      const duration = endTime - startTime;
      console.log(`API response time: ${duration}ms`);

      setgetSubSource(subSourceData.data);
      const leadInfo = leadData.data[0];
      setLeadData(leadInfo);
      setFormData({
        name: leadInfo?.first_name || "",
        mobileNumber: leadInfo?.dealership_contact || "",
        email: leadInfo?.dealership_email || "",
        source: leadInfo?.subsource || "",
        source_id: leadInfo?.source_id || "",
        city_id: leadInfo?.city_id || "",
        yodhaid: leadInfo?.sfa_referral_user_id || "",
        yodhaCode: leadInfo?.sfa_refferal_user_name || "",
      });
      setReason(leadInfo?.lost_reason || "");
      setComment(leadInfo?.comment || "");
      setPreviousFormData({
        name: leadInfo?.first_name || "",
        mobileNumber: leadInfo?.dealership_contact || "",
        email: leadInfo?.dealership_email || "",
        source: leadInfo?.subsource || "",
        source_id: leadInfo?.source_id || "",
        yodhaid: leadInfo?.sfa_referral_user_id || "",
        yodhaCode: leadInfo?.sfa_refferal_user_name || "",
      });

      // Call getUnreadMessage here after setting up state
      // getUnreadMessage(leadInfo?.dealership_contact || ""); // Assuming dealership_contact is the mobile number
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  const handleChange = ({ target: { name, value } }) =>
    setFormData((prev) => ({ ...prev, [name]: value }));
  const handleSubSource = (selected) =>
    setFormData((prev) => ({
      ...prev,
      source_id: selected.id,
      source: selected.subsource,
    }));
  const handleCityChange = (selected) => {
    setFormData((prev) => ({
      ...prev,
      city_id: selected.id
    }));
  };
  const handleRegistration = async () => {
    const {
      user_data: { user_id },
    } = JSON.parse(secureStorage.storage.user_information);
    try {
      const userInfo = getCurrentUserDetails();
      const { userId } = userInfo;

      const response = await MasterService.post(
        "/partner/partner/save-basic-details",
        {
          ...formData,

          name: formData.name,
          email: formData.email,
          mobile: formData.mobileNumber,
          subsource_id: formData.source_id,
          sfa_referral_user_id: formData.yodhaid,
          user_type: "1",
          source: "Sangam Backend",
          updated_by: userId,
          is_pan_verified: 2,
          step: 1,
          term_condition: true,
          partner_id: lead.id,
          user_id: lead.user_id,
        }
      );
      response.data.status === 200
        ? toast.success("Partner updated successfully!")
        : toast.error(response.data.message || "Error");

      if (response.data.status === 200) {
        await detectChanges(formData);
      }
    } catch {
      toast.error("An error occurred while saving details.");
    }
  };

  const detectChanges = async (formData) => {
    const changes = [];
    for (const key in formData) {
      if (formData[key] !== prevFormData[key]) {
        changes.push({
          fieldName: key,
          aId: 2,
          leadId: lead.id,
          userId: lead?.user_id,
          previousValue: prevFormData[key],
          currentValue: formData[key],
          comment: `${key} Changed`,
        });
      }
    }
    changes.forEach(async (change) => await preLeadFormActivity(change));
    return changes;
  };

  const convertLead = async (lead1) => {
    try {
      const response = await MasterService.post(
        "/partner/partner/partner-lead-conversion",
        { partner_id: lead1?.id }
      );
      // setTimeout(() => window.location.reload());
      if (response.data.length) {
        setShowOtpModal(false);
        const activilyLog = {
          leadId: lead.id,
          userId: lead?.user_id,
          comment: "Registered as Partner",
          aId: 3,
        };
        registerAsPartner(activilyLog);
        toast.success("Lead Converted Successfully");
        setLeadData((prev) => ({
          ...prev,
          status: "4",
        }));
      }
    } catch {
      setShowOtpModal(false);
    }
  };
  const markAsLost = async () => {
    try {
      let token = JSON.parse(secureStorage.storage.user_information);

      const reqData = {
        lead_id: lead.id,
        user_id: lead.user_id,
        status: 1,
        lost_reason: reason,
        comment: comment,
        updated_by: token.id,
      };

      const response = await MasterService.post(
        "/partner/partner/mark-as-lost",
        reqData
      );
      if (response.status === 200) {
        const activilyLog = {
          leadId: lead.id,
          userId: lead?.user_id,
          comment: `Reason : ${response?.data?.data?.updateResult?.lost_reason} | comment : ${response?.data?.data?.updateResult?.comment}`,
          aId: 5,
        };
        setLeadData((prev) => ({
          ...prev,
          status: "11",
        }));
        lostLeadActivity(activilyLog);
        setReject(false);
        toast.success("Partner Marked as Lost");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  addValidationRule("isValidName", (values, value) =>
    /^[A-Za-z\s]+$/.test(value)
  );
  addValidationRule("isValidMobile", (values, value) =>
    /^[6-9]\d{9}$/.test(value)
  );
  addValidationRule("isValidEmail", (values, value) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
  );

  const RejectedPartnerPopup = () => {
    setReject(true);
  };

  const handleRejectReasonChange = (e) => {
    setReason(e.reason);
  };
  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const renderActivityModal = () => {
    return (
      showActivityModal && (
        <PreLeadActivity
          show={showActivityModal}
          leadId={leadData.id}
          closeModal={() => setShowActivityModal(false)}
        ></PreLeadActivity>
      )
    );
  };
  
  const renderPartnerCallHistoryModal = () => {
    return (
      showCallActivityModal && (
        <PartnerCallHistory
          show={showCallActivityModal}
          leadId={leadData.id}
          closeModal={() => setShowCallActivityModal(false)}
        ></PartnerCallHistory>
      )
    );
  };

  if(isLoading) {
    return (
      <div className="loading-spinner">
        <Loader />
      </div>
    );
  }

  const handleClickToCall = async () => {
    try {
      setIsLoading(true);
      const userInfo = JSON.parse(localStorage.getItem("user_information"));
      const url = "partner/partner-support/click-to-call";
      const reqBody = {
        partner_id: leadData?.id,
        customer_number: `+91${leadData?.dealership_contact}`,
        agent_number: `+91${userInfo?.mobile}`,
        lms_type: "sales"
      }
      const response = await MasterService.post(url, reqBody);
      setIsLoading(false);
      if (response.data.error) return toast.error(response.data?.message);
      return toast.success("Success");
      
    } catch (error) {
      setIsLoading(false);
      toast.error("Internal server error");
    }
  }

  return (
    <div className="container-fluid">
      <div className="loan-bx-ribben">
        <ul className="maindetail-list">
          <li className="partner-item">
            <div className="patnerlist">
              Name: <span>{leadData?.first_name}</span>
            </div>
            <div>
              (ID: AMB<span>{leadData?.id}</span>)
            </div>
          </li>
          <li className="partner-item">
            <div className="partnerwhatsapp">
              <span className="mrg-r10">Mobile: </span>
              <span className="mrg-r10">
                {leadData?.dealership_contact && `XXXXXXX${leadData?.dealership_contact.slice(-3)}`}
              </span>
             {access.click_to_call_sales ===1 && <div onClick={handleClickToCall}>
                <img src = {callicon} alt = "" style={{width:"30px" , height : "30px" , marginLeft : "5px" , cursor : "pointer"}}/>
              </div>}

              {/* <span>
                <i
                  onClick={togglePopup}
                  className="ic-whatsapp icons-normal whatsappchat"
                ></i>
              </span> */}
            </div>
          </li>
          <li className="partner-item">
            <div>
              Email: <span>{leadData?.dealership_email}</span>
            </div>
          </li>
          <li className="partner-item">
            <div className="mb-b5">
              Created at:{" "}
              <span>{getDateAndTimeFormat(leadData?.created_date)}</span>
            </div>
            <div>
              Updated at:{" "}
              <span>{getDateAndTimeFormat(leadData?.updated_date)}</span>
            </div>
          </li>
          <li className="partner-item">
            <span
              className="partnernewlead"
              style={{
                backgroundColor: getStatusUtility(leadData.status).color,
              }}
            >
              {getStatusUtility(leadData.status).status}
            </span>
          </li>
        </ul>
      </div>
      <div style={{display : "flex" , flexDirection : "row-reverse"  , justifyContent : "space-between" }}>
        <div className="partnerbtn" >
            <button
                className="partnerregisterbtn mrg-r10"
                onClick={() => setShowCallActivityModal(true)}
            >Call History</button>
          {access.partner_lead_history ? (
            <button
              className="partnerregisterbtn mrg-r10"
              onClick={() => setShowActivityModal(true)}
            >
              Partner Lead History
            </button>
          ) : (
            ""
          )}
          {renderActivityModal()}
          {renderPartnerCallHistoryModal()}
          {/* {access.comms_status && (
          // <button className="partnerregisterbtn mrg-r10">Comms Status</button>
        )} */}
          {access.register_as_partner ? (
            <button
              className="partnerregisterbtn mrg-r10"
              onClick={() => setShowOtpModal(true)}
              disabled={leadData.status != 10}
            >
              Register as Partner
            </button>
          ) : (
            ""
          )}
          {access.mark_lost ? (
            <button
              disabled={leadData.status === "11"}
              className="lost-btn"
              onClick={RejectedPartnerPopup}
            >
              Mark Lost
            </button>
          ) : (
            ""
          )}
        </div>
      
      </div>
      <div>
          {/* <CallDetailCard  lead = {lead.id}/> */}
        </div>

      <div className="partnerlistflexcard" >
      <div className="partnerfloatleft">
      <div className="search-wrap">
        {two_way_communication === 1 && (
          <div className="partnerdetailslist">
            <div>Partner Lead Details</div>
            <div className="partnerwhatsapp mrg-r10">
              {unReadMessage?.latest_created_date && (
                <div className="partnerstatus">
                  Last updated:{" "}
                  {formatDate(unReadMessage?.latest_created_date ?? "")}
                </div>
              )}
              <span className="partnerrefreshbtn mrg-r10">
                <i
                  className={
                    isLodingMsg
                      ? "spinner-border text-primary"
                      : "ic-refresh icons normal"
                  }
                  title="Refresh UnRead Messages"
                  onClick={() => {
                    // getUnreadMessage(leadData?.dealership_contact);
                  }}
                ></i>
              </span>
              <div className="partnerwhatsapp">
                <span className="patrnermessage">
                  {unReadMessage?.unread_count} unread messages
                </span>
                <i
                  onClick={togglePopup}
                  title="Chat with Partner"
                  className="ic-whatsapp icons-normal whatsappchat"
                ></i>
              </div>
            </div>
          </div>
        )}
        <Formsy autoComplete="off" onValidSubmit={handleRegistration}>
          <div className="row">
            <fieldset className="col-sm-3 mb-b20">
              <FormsyInputField
                id="name"
                name="name"
                type="text"
                value={formData.name}
                placeholder=" "
                onChange={handleChange}
                label="Partner Lead Name"
                validations="isValidName"
                validationError="Name should contain only alphabets"
                required
              />
            </fieldset>
            <fieldset className="col-sm-3 mb-b20">
              <FormsyInputField
                id="mobile"
                name="mobileNumber"
                type="text"
                value={formData.mobileNumber}
                placeholder=" "
                label="Mobile No"
                onChange={handleChange}
                validations="isValidMobile"
                validationError="Mobile number should be 10 digits and start with 6, 7, 8, or 9"
                required
              />
            </fieldset>
            <fieldset className="col-sm-3 mb-b20">
              <FormsyInputField
                id="email"
                name="email"
                type="text"
                value={formData.email}
                placeholder=" "
                onChange={handleChange}
                label="Email ID"
                // validations="isValidEmail"
                // validationError="Email should contain @ and ."
                // required
              />
            </fieldset>
            <fieldset className="col-sm-3 mb-b20">
              <Select
                id="subsource_id"
                options={getSubSource}
                onChange={handleSubSource}
                name="subsource_id"
                placeholder={formData.source || "Sub Source*"}
                getOptionLabel={(option) => option.subsource}
                getOptionValue={(option) => option.id}
                value={getSubSource.find(
                  (option) => option.id === formData.source_id
                )}
              />
            </fieldset>
            <fieldset className="col-sm-3 mb-b20">
              <FormsyInputField
                id="direct_partner"
                name="yodhaCode"
                type="text"
                label={"Partner Sales Yoddha*"}
                value={lead.sfa_referral_user_id}
                onChange={handleChange}
                disabled={true}
              />
            </fieldset>
            <fieldset className="col-sm-3 mb-b20">
              <Select
                id="city_id"
                options={cityList}
                onChange={handleCityChange}
                name="city_id"
                placeholder={"Select city..."}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={cityList.find(
                  (option) => option.id === formData.city_id
                )}
              />
            </fieldset>
          </div>
          {access.save ? (
            <button className="partnerregisterbtn" type="submit">
              Save
            </button>
          ) : (
            ""
          )}
        </Formsy>
      </div>
      </div>
     <div className="partnerfloatright">
      <div className="search-wrap">
      <NewCallDetailCard leadData={lead} source = {"sales"}/>
      </div>
      </div>

      </div>


      {showOtpModal && (
        <Modal
          show={showOtpModal}
          handleClose={() => setShowOtpModal(false)}
          removeHeight={true}
        >
          <VerifyOtpModal lead={lead} convertLead={convertLead} />
        </Modal>
      )}
      <Modal1 isOpen={reject} togglePopup={() => setReject(false)}>
        <div className="modal-body">
          <div className="modal-header">
            <label> Are you sure you want to mark the lead as lost?</label>
          </div>
          <fieldset className="form-filed" style={{ marginBottom: 20 }}>
            <div className="material">
              <label>
                Select a reason <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                id="mark_as_lost"
                onChange={handleRejectReasonChange}
                options={rejectReasons}
                name="mark_as_lost"
                placeholder="Select Rejection Reason"
                value={rejectReasons.find(({ reason: r }) => r === reason)}
                getOptionLabel={({ reason }) => reason}
                getOptionValue={({ id }) => id}
              />
            </div>
          </fieldset>
          <fieldset>
            <div className="material">
              <textarea
                name="reject_reason_comment"
                placeholder=""
                className="form-input"
                rows="3"
                value={comment}
                onChange={handleComment}
              ></textarea>
              <label data-label="Comment" className="form-label"></label>
            </div>
          </fieldset>

          <div
            style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
          >
            <button
              className="btn btn-primary"
              onClick={markAsLost}
              disabled={!reason}
            >
              Mark As Lost
            </button>
          </div>
        </div>
      </Modal1>
      <LoggedinPopup isOpen={isOpen} togglePopup={togglePopup}>
        <PartnerWhatsAppChat lead={leadData} />
      </LoggedinPopup>
    </div>
  );
};

export default PartnerLeadDetailsPage;
