import React, { useState, useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { LEADS_HISTORY_QUERY } from "../../services/leads.gql";
import { useSelector, useDispatch } from "react-redux";
import LeadHistory from '../lead-list/LeadHistory'; 
import { useTranslation } from "react-i18next";
import { getLeadRemarks, getCheckList } from '../../store/action/allAction';
import { useParams } from 'react-router-dom';
import callicon from "./../../webroot/images/callicon.svg";
import crypto from '../../config/crypto'
import dateFormat from "dateformat";
import FollowUpsForm from './Followup';
import { gql } from '@apollo/client';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { IS_USER_ACCESS_PAGE } from '../../../src/config/constants';
import BankerPOCMapping from './BankerPOCMapping';
import { GET_BANK_POC_MAPPING } from '../../services/bank.gql';

const LoanRibbenBox = (props) => {
    const { toggleTip, toggleTip1, toggleTip2, toggleTip3 } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation('language');
    const client = useApolloClient();
    const [ViewTimeLine, setViewTimeLine] = useState(false); 
    const [followUp, setFollowUp] = useState(false); 
    const [bankerPOCModalVisibility, setBankerPOCModalVisiblity] = useState(false); 
    const [historyList, setHistoryList] = useState([]);
    const [followUphistoryList, setFollowUphistoryList] = useState([]);
    const [bankPOCMappingData, setBankPOCMappingData] = useState([]);
    const [bankRecordData, setBankRecordData] = useState([]);
    const [bankerName, setBankerName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { params } = useParams();
    const lead_id = params && crypto.decode(params.lead_id) || "";
    const { masterdata, leadDetail, remarksHistory } = useSelector(({ masterdata, lead }) => {
        return {
            masterdata,
            leadDetail: lead.leadDetail,
            remarksHistory: lead.remarksHistory || []
        }
    });


    const getBankPOCMappingData = async (lead_id) => {
        executeGraphQLQuery(GET_BANK_POC_MAPPING(lead_id), client)
          .then((result) => {
            if (result.data) {
              const record = result.data.get_bank_poc_mapping || {};
              if (record?.bank_record.length > 0) {
                setBankerName(record?.bank_record[0]?.name || "");
                setBankRecordData(record?.bank_record);
                setBankPOCMappingData(record?.bank_poc_mapping);
              }
            } else {
              toast.error("Internal Server Error");
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      };
    
      useEffect(() => {
        if (leadDetail?.id) {
          getRemarksHistory(leadDetail.id);
          getBankPOCMappingData(leadDetail.id);
        }
      }, [leadDetail]);

    // const bodyClick = () => {
    //     toggleTip(false);
    //     toggleTip1(false);
    //     toggleTip2(false);
    //     toggleTip3(false);
    // }

    // const getTimeLineListData = async (lead_id) => {
    //     executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client).then((result) => {
    //         setHistoryList(result.data.get_history)
    //     })
    //         .catch((error) => {
    //             console.error('Query error:', error);
    //         });
    // }

    const bodyClick = () => {
        toggleTip(false);
        toggleTip1(false);
        toggleTip2(false);
        toggleTip3(false);
    }  

    const { lead } = useSelector(({ lead }) => ({ lead }));

    let assign_to = lead?.leadDetail?.assign_to;
    // const user_information = useSelector((state) => state.user.user_information);
    // const UserInfo = JSON.parse(user_information)

    const handleClickToCall = () => {
        setIsLoading(true);
        let lead_id = lead?.leadDetail?.id || "";
        let variables = ''
        
        let mutation = gql`mutation{
            dialer_call(lead_id: ${lead_id},user_id: ${assign_to})
        }`;

        executeGraphQLMutation(mutation, variables, client).then(result => {
            console.log(result.data.dialer_call)
          if (result.data.dialer_call=='Dialer Calling...') {
            setIsLoading(false);
            toast.success(result.data.dialer_call, {fadeOut: 5000})
          } else {
            setIsLoading(false);
            toast.error(result.data.dialer_call, {fadeOut: 5000});
          }
        //   toast.error(result.data.dialer_call, {fadeOut: 5000});
        //   toast.success('Dialer Calling...', {fadeOut: 5000});
        }).catch((error) => {
          setIsLoading(false);
        });
    }

    const getTimeLineListData = async (lead_id) => {
        executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client).then((result) => {
            setHistoryList(result.data.get_history)
        })
            .catch((error) => {
                console.error('Query error:', error);
            });
    }
    
    const showModalViewTimeLine = (lead_id) => {
        setViewTimeLine(true);
        document.body.classList.add('overflow-hidden');
        getTimeLineListData(lead_id);
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    const showModalFollowUp = (lead_id) => {
        setFollowUp(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalFollowUp = () => {
        setFollowUp(false);
        document.body.classList.remove('overflow-hidden');
    };

    const handleBankerPocEdit = () => {
        setBankerPOCModalVisiblity(true);
        document.body.classList.add('overflow-hidden');
    }

    const handleBankerModalClose = () => {
        setBankerPOCModalVisiblity(false);
        document.body.classList.remove('overflow-hidden');
        setTimeout(() => {
            window.location.reload();
        }, 900);
    }

    const getRemarksHistory = (lead_id) => {
        dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))
    }

    const maskMobileNumber = (phoneNumber) => {
        if (typeof phoneNumber === 'string' && phoneNumber.length >= 4) {
            const lastFourDigits = phoneNumber.substr(-2);
            const maskedDigits = '*'.repeat(phoneNumber.length - 2);
            return maskedDigits + lastFourDigits;
        } else {
            return phoneNumber;
        }
    };   

    useEffect(() => {
        if (!masterdata.check_list) {
          dispatch(getCheckList(client))
        }
    }, [])



    let calling_reasons = useMemo(() => {
    let substatus = (masterdata?.check_list?.length) ? masterdata?.check_list.filter(ob => ob?.statuslang?.status_id == 6)?.[0]?.substatus : [];
    let calling_reason = substatus?.filter(data => data.id === (leadDetail?.lead_details?.call_status === 'connected' ? 22 : 23))?.map(v => v.calling_reasons)?.[0] || [];
    return calling_reason?.map(v => ({ ...v, value: v.id, label: v.calling_reason }))
    }, [leadDetail?.lead_details?.call_status]);

    calling_reasons = calling_reasons?.filter(data => data.id === leadDetail?.lead_details?.calling_reason_id)?.map(v=>v)?.[0]?.calling_reason || '';

    let dialer_call = IS_USER_ACCESS_PAGE("dialer", "dialer")?.is_edit_access;

    if (leadDetail && leadDetail.customer) {

        let folloupText = 'N/A'

        if(leadDetail.followup_status =='1' && leadDetail.followup_date){
            if(new Date() <= new Date(leadDetail.followup_date)){
                folloupText = `Upcoming :${dateFormat(leadDetail.followup_date, 'd mmm,yyyy hh:MM')}`
            }else{
                folloupText = `Overdue : ${dateFormat(leadDetail.followup_date, 'd mmm,yyyy hh:MM')}` 
            }
        } 

        class NameFormatter {
            capitalizeFirstLetter(word) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
          
            capitalizeEachWord(name) {
                return name?.split(' ').map(this.capitalizeFirstLetter).join(' ');
            }
          
            formatFirstName(firstName) {
                return this.capitalizeEachWord(firstName);
            }
        }
        const nameFormatter = new NameFormatter();

        return (
            <div className="loan-bx-ribben">
                <ul className="maindetail-list clearfix ">
                    <li className="maindetail-item">
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  Lead Id :  <span> # {leadDetail.id}</span>
                        </h3>
                    </div>
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                            <div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
                            <span>➣  Customer : </span>
                            <span style={{display:"flex",alignItems:"center"}} title={leadDetail?.customer?.first_name +' '+leadDetail?.customer?.last_name}>
                                {leadDetail?.customer?.first_name +' '+leadDetail?.customer?.last_name}
                                {
                                dialer_call=='1' ?
                                    leadDetail.fulfillment_type === 'ambak' && 
                                        <img onClick={handleClickToCall} style={{marginLeft:5}} src={callicon} alt='logo' width={25} height={25} />
                                    :null
                                }
                            </span>
                            </div>
                        </h3>
                    </div>
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  Source :  <span> {leadDetail?.leadSourceInfo?.source || 'N/A'}</span> <span> {leadDetail?.is_otp_verified ? "[OTP Verified]" : ""}</span>
                        </h3>
                    </div>
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  Sub Source :  <span> {leadDetail?.leadSourceInfo?.subSource || 'N/A'}</span> <span> {leadDetail?.is_revisit ? `[${leadDetail.is_revisit} Revisits]` : ''}</span>
                        </h3>
                    </div>
                        {/* <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title="">{leadDetail.id}</li>
                            </ul>
                        </div> */}
                    </li>                   

                    {/* <li className="maindetail-item">
                        <h3 className="subheading">Customer Details</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title=''>
                                    <span className="buyer-txt" title={leadDetail?.customer?.first_name +' '+leadDetail?.customer?.last_name}>{leadDetail?.customer?.first_name +' '+leadDetail?.customer?.last_name}</span>
                                    <span onClick={() => { toggleTip(true); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} title="" className="tooltio-ic"><i className="ic-info-icon1"></i>
                                    </span>

                                    <Tooltip
                                        html={(
                                            <div ref={tipContentRef} className="controlled-example tooltip-main">
                                                <div className="tooltip-data">

                                                    <span className="controlled-example_close-button" onClick={() => toggleTip(false)}><i className="ic-clearclose"></i></span>
                                                    <ul className="tooltip-user-detail-span-label">
                                                        <li>
                                                            <span>Customer Name</span>
                                                            <label>{leadDetail?.customer?.first_name + ' ' + leadDetail?.customer?.last_name}</label>

                                                        </li>
                                                        <li>
                                                            <span>Customer Number</span>
                                                            <label>{maskMobileNumber(leadDetail.customer.mobile)}</label>

                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                        open={tipOpen}
                                        tagName="span"
                                        position="bottom"
                                        trigger="click"
                                        interactive={true}
                                        animateFill={false}
                                        arrow={true}
                                        arrowSize="small"
                                        className="tootip-outer"
                                        forceDirection
                                    >
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                    </li> */}

                    <li className="maindetail-item">
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  Bank :  <span>{nameFormatter.formatFirstName(leadDetail?.lead_details?.bank?.banklang?.bank_name)}</span>
                        </h3>
                    </div>
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  Product : <span title={leadDetail?.loan_type}>{(leadDetail.loan_type===1)?'Home Loan':'LAP'}</span>
                        </h3>
                    </div>
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  Fulfillment : <span title={nameFormatter.formatFirstName(leadDetail?.fulfillment_type)}>{nameFormatter.formatFirstName(leadDetail?.fulfillment_type)}</span>
                        </h3>
                    </div>
                    </li> 

                    <li className="maindetail-item">
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  Partner :  <span>{nameFormatter.formatFirstName(leadDetail?.partner_name) || ""}</span>
                        </h3>
                    </div>
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  RM : <span title={leadDetail?.loan_type}>{leadDetail?.rmdetails?.label ? nameFormatter.formatFirstName(leadDetail?.rmdetails?.label) : ""}</span>
                        </h3>
                    </div>
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  BANKER : 
                        <span title={bankerName}>{bankerName ? ` ${nameFormatter.formatFirstName(bankerName)}` : " N/A"}
                            <button
                                className="btn btn-default" 
                                onClick={handleBankerPocEdit}
                                style={{ marginLeft: "10px" }}
                            >
                                <i className="ic-createmode_editedit icons-normal"></i>
                            </button>
                        </span>
                        </h3>
                    </div>
                    
                    </li> 
                    <li className="maindetail-item">
                    <div className="sub-value">
                        <h2 style={{marginBottom:5,fontSize:18}}>Status: <span>{leadDetail?.status_info?.statuslang?.status_name}</span></h2>
                        <h3 className="sub-value-list">
                        ➣  Sub Status :  <span>{nameFormatter.formatFirstName(leadDetail?.sub_status_info?.substatuslang?.sub_status_name)}</span>
                        </h3>
                    </div>
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  Last Updated: <span>{dateFormat(leadDetail.updated_date, 'd mmm,yyyy hh:MM')}</span>
                        </h3>
                    </div>
                    
                    </li>   

                    <li className="maindetail-item">
                    <div className="sub-value">
                        <h2 style={{marginBottom:5,fontSize:18}}>Last Call Status</h2>
                        <h3 className="sub-value-list">
                        ➣  Sub Status :  <span>{leadDetail?.lead_details?.call_status === 'connected' ? 'Connected': leadDetail?.lead_details?.call_status === 'not_connected' ? 'Not Connected' : 'N/A'}</span>
                        </h3>
                    </div>
                    <div className="sub-value">
                        <h3 className="sub-value-list">
                        ➣  Response: <span>  { calling_reasons || 'N/A'}</span>
                        </h3>
                    </div>
                    
                    </li>   

                    <li className="maindetail-item">
                    <div className="sub-value">
                        <h2 style={{marginBottom:5,fontSize:18}}>Follow Ups</h2>
                        <h3 className="sub-value-list">
                        ➣ 
                        {folloupText}
                         </h3>
                         <h3 className="sub-value-list"  >
                        ➣ <span style={{cursor:"pointer",textDecoration:"underline"}} onClick={showModalFollowUp}>Manage Followup</span>
                         </h3>
                    </div>
                    </li>   

                    {/* <li className="maindetail-item">
                        <h3 className="subheading">Partner</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={leadDetail?.partner_name}>{leadDetail?.partner_name || ""}</li>
                            </ul>
                        </div>
                    </li> */}

                    {/* <li className="maindetail-item">
                        <h3 className="subheading">RM</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={leadDetail?.rmdetails?.label}>{leadDetail?.rmdetails?.label ? leadDetail?.rmdetails?.label : ""}</li>
                                <li title={leadDetail?.rmdetails?.mobile}>{leadDetail?.rmdetails?.mobile ? maskMobileNumber(leadDetail?.rmdetails?.mobile) : ""}</li>
                            </ul>
                        </div>
                    </li>  */}

                    {/* <li className="maindetail-item">
                        <h3 className="subheading">Loan Status Date</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                {leadDetail?.splitpayment?.map((data, index) => {
                                    return <li>Disbursal-{index + 1} on {dateFormat(data?.disbursed_date, 'd mmm,yyyy')}</li>
                                })}
                                {leadDetail?.lead_details?.approval_date ? <li title={leadDetail?.lead_details?.approval_date}>Sanctioned on {dateFormat(leadDetail?.lead_details?.approval_date, 'd mmm,yyyy')}</li>:""}
                                {leadDetail?.lead_details?.login_date ? <li title={leadDetail?.lead_details?.login_date}>Logined on {dateFormat(leadDetail?.lead_details?.login_date, 'd mmm,yyyy')}</li>:""}
                            </ul>
                        </div>
                    </li>  */}

                    {/* <li className="maindetail-item">
                        <h3 className="subheading">Fulfillment Type</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li>
                                    <span className="truncate" title="Self">{leadDetail?.fulfillment_type}</span>
                                </li>
                            </ul>
                        </div>
                    </li> */}
                    
                    {/* <li className="maindetail-item">
                        <h3 className="subheading">Case Status</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={leadDetail?.status_info?.statuslang?.status_name}>{leadDetail?.status_info?.statuslang?.status_name}</li>
                            </ul>
                        </div>
                    </li> */}

                    {/* <li className="maindetail-item">
                        <h3 className="subheading">Bank Name</h3>
                        <div className="sub-value">
                            <ul className="sub-value-list">
                                <li title={leadDetail?.lead_details?.bank?.banklang?.bank_name}>
                                    <span className='truncate'>{leadDetail?.lead_details?.bank?.banklang?.bank_name}</span>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <button type="button" className="btn-primary-accent history-btn" onClick={() => showModalViewTimeLine(leadDetail.id)}>View History
                            <i className="ic-arrow_forward"></i>
                        </button>
                    </li>  */}
                </ul>
                <div className="view-timeline-popup image-timeline-popup">
                    {ViewTimeLine ? (
                        <div className="view-timeline-popup image-timeline-popup">
                            <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
                                <div className="modal-header">
                                    <h2>Customer Timeline</h2>
                                </div>
                                <LeadHistory historyList={historyList} followUphistoryList={followUphistoryList} isLoading={isLoading} t={t} />
                            </Modal>
                        </div>
                    ) : ''}
                </div> 

                <div className="view-timeline-popup image-timeline-popup">
                  
                        <div className="view-timeline-popup image-timeline-popup">
                            <Modal show={followUp} handleClose={hideModalFollowUp} width={650} >
                                <FollowUpsForm />
                            </Modal>
                        </div>
                    
                </div> 
                
                <div className="view-timeline-popup image-timeline-popup">
                  
                        <div className="view-timeline-popup image-timeline-popup">
                            <Modal show={bankerPOCModalVisibility} handleClose={handleBankerModalClose} >
                                <BankerPOCMapping
                                    existingbankPOCMappingData={bankPOCMappingData}
                                    existingBankRecordData={bankRecordData}
                                />
                            </Modal>
                        </div>
                    
                </div> 

            </div>
        )
    }
}

const Modal = ({ handleClose, show, children,width }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main' style={{ width: width ? width : 'auto' }}>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default LoanRibbenBox;
