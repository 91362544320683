import React, { useState } from "react";
import SupportPartnerInfo from "./SupportPartnerInfo";
import SupportSearchFilter from "./SupportSearchFilter";
import SupportListing from "./SupportListing";


const SupportPartnerListing = () =>{
    const [searcFilter , setSearchFilter] = useState({})
    const [buttonClick , setButtonClick] = useState(0);
  
    const handleSearchFilters = (value) => {
        setSearchFilter(value)
    }

    const handleButtonClick = () => {
        setButtonClick((prev) => prev + 1);
    }

    return(
        <>
        <div className="container-fluid">
          {/* <SupportPartnerInfo/> */}
          <SupportSearchFilter handleSearchFilters = {handleSearchFilters}  handleButtonClick = {handleButtonClick}/>
          <SupportListing   searcFilter ={searcFilter} buttonClick = {buttonClick}/>
       </div>

        </>
    );
}



export default SupportPartnerListing;