import DateFormate from "dateformat";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { DEALER_WALLET_ID } from "../../config/constants";
import { DealerService } from "../../services/index";
import MasterService from "../../services/MasterService";
import Modal from "../elements/partnerModal";
import LeadData from "./leadHistory";
import PartnerHistory from "../lead-list/PartnerHistory";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQueryFinex";
import { LEADS_COUNT_DATA } from "../../services/leadsData.gql";
import Pagination from "../elements/pagination";
import {IS_USER_ACCESS_PAGE} from '../../config/constants';
import WhatsAppLogTable from "./pre-register-lead-manager/components/whatsappLog";
import { MarkAsDemo } from "./component/mark-as-demo/MarkAsDemo";

class CallerSearchResult extends Component {
  constructor(props) {
   
    
    super(props);
    this.state = {
      dealerListData: props.dealerListData,
      leadsCount: props.leadsCount,
      mergeData: props.mergeData,
      loading: false,
      pageloading: false,
      statusClassname: {
        ready_for_verification: "nav-item active",
        pending: "nav-item",
        active: "nav-item ",
        rejected: "nav-item",
        inactive: "nav-item",
        incomplete: "nav-item",
        blacklist: "nav-item",
        finmissed: "nav-item",
        kycmissed: "nav-item",
        all: "nav-item",
        only_registerd: "nav-item",
        doc_pending: "nav-item",
        all_pending: "nav-item",
        viewModal: false,
        leadsid: 0,
        viewHistory: false,
        leadcountdata: 0,
        LeadsData: [],
        partner_id: '',
        rowData: [],
        colDefs: [
          { field: "Partner Deatils" },
          { field: "Status" },
          { field: "Source/Sub-SOurce" },
          { field: "Assigned RM" },
          { field: "LggedIN" },
          { field: "Last App Visit" },
          { field: "Latest Platform" },
        ],
      },
      filterData: this.props.filterData,
      dealerUpdateData: {},
      //   blackListPopup: false,
      blackListUpdateData: {},
      currentIndex: null,
      current_org_name: null,
      historyPopup: false,
      histoy_dealer_id: 0,
      openedRowIndex: null,
      whatsappLog: null,
      showWhatsappModal: false,
      currentPartnerData: null,
      showAddPartnerButton: props?.showAddPartnerButton ?? true,
    };
  }

  async componentDidMount() {}

  showRowIndex = (index) => {
    this.setState(
      (prevState) => ({
        openedRowIndex: prevState.openedRowIndex === index ? null : index,
      }),
      () => {
        const tooltips = document.querySelectorAll(".toggle-checkbox:checked");
        tooltips.forEach((tooltip, i) => {
          if (i !== index) {
            tooltip.checked = false;
          }
        });
      }
    );
  };

  closeHistoryModel = () => {
    this.setState({ historyPopup: false });
  };

  handleHistory = (id) => {
    this.setState({ historyPopup: true });
    this.setState({ histoy_dealer_id: id });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dealerListData !== this.props.dealerListData ||
      nextProps.loading !== this.props.loading ||
      nextProps.leadsCount !== this.props.leadsCount
    ) {
      this.setState({
        dealerListData: nextProps.dealerListData,
        loading: nextProps.loading,
        pageloading: nextProps.pageloading,
        leadsCount: nextProps.leadsCount,
      });
    }
  }

  manageleads = () => {
    return this.state.leadsCount.map((count) => {
      <td>{count.co_applicant.length}</td>;
    });
  };
  openModal = () => {
    this.setState({ viewModal: true });
  };

  closeModal = () => {
    this.setState({ viewModal: false });
  };

  openModal1 = () => {
    this.setState({ viewHistory: true });
  };
  showHistory = (id) => {
    this.setState({ partner_id: id});
    // MasterService.get(
    //   `partner/partner/get-activity-hstory/?partner_id=${id}`
    // ).then((response) => {
    //   this.setState({ partner_id: id});
    // });
  };
  LeadsData = async (id) => {
    let filterData = {};
    filterData.id = id;
    await executeGraphQLQueryFinex(LEADS_COUNT_DATA(filterData)).then(
      (response) => {
        this.setState({ LeadsData: response });
      }
    );
  };
  hideModalViewTimeLine = () => {
    this.setState({ viewHistory: false });
   
    document.body.classList.remove("overflow-hidden");
  };

  calculateAppInactivityStatus(lastLoginDateTime) {
    
     // Handle null or undefined input
     if (!lastLoginDateTime) {
      return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
      };
  }

    const lastLoginDate = new Date(lastLoginDateTime);

    const timeDifference = new Date() - lastLoginDate;

    const daysInactive = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursInactive = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesInactive = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const secondsInactive = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days: daysInactive,
      hours: hoursInactive,
      minutes: minutesInactive,
      seconds: secondsInactive,
    };
  }

 sendWhatsappMsg = async (dealerdata) => {
    try {
      const confirm = window.confirm(
        "Are you sure you want to share whatsapp message to this partner ?"
      );

      if (confirm) {
        const params = {
          partner_id: dealerdata?.id,
          mobile: dealerdata?.dealership_contact,
          notification_id:40
        };
        const response = await MasterService.post(
          "partner/partner/resend-whatsapp-message",
          params
        );

        if (response.status === 200) {
          toast.success(
            `Status : ${response?.data?.message} on - ${params?.mobile}`
          );
        }
      }
    } catch (error) {
    }
  };

  getWhatsappLogs = async (dealerdata) => {
    try {
      MasterService.get(
        "/partner/partner/view-whatsapp-status?" +
          `partnerId=${dealerdata?.id}&notificationId=${40}`
      )
        .then((response) => {
          if (response.data.length) {
            this.setState({whatsappLog: response.data});
            // this.setState({showWhatsappModal: true});
          }
        })
        .catch((error) => {
          this.setState({showWhatsappModal: true});
        });
    } catch (error) {
    }
  };

  showWhatsappLogModal = async (dealerdata) => {
    await this.getWhatsappLogs(dealerdata);
    this.setState({currentPartnerData: dealerdata})
    this.setState({showWhatsappModal: true});
    document.body.classList.add("overflow-hidden");
  };

  renderShareAndViewButton = (dealerdata) => {
    return (
      <div style={styles.whatsappContainer}>
        <div
          className="commoniconbtn mrg-r10"
          onClick={() => this.sendWhatsappMsg(dealerdata)}
          title="Re-Send Whatsapp Notification"
        >
          <i className="ic-share"></i>
        </div>
        <div
          className="commoniconbtn"
          onClick={() => this.showWhatsappLogModal(dealerdata)}
          title="View Whatsapp Notification Log"
        >
          <i className="ic-remove_red_eyevisibility"></i>
        </div>
      </div>
    );
  };

  render() {

    let actions =  IS_USER_ACCESS_PAGE("partner", "update")?.is_edit_access;
    let create_partner =  IS_USER_ACCESS_PAGE("partner", "create")?.is_edit_access;
    let view_history =  IS_USER_ACCESS_PAGE("partner", "activity_list")?.is_edit_access;
    let edit_partner =  IS_USER_ACCESS_PAGE("partner", "update")?.is_edit_access;
    const { dealerCountStatusDetail } = this.props;
    const { LeadsData } = this.props;
    const { viewHistory } = this.props;
    return this.state.loading ? (<div className="loading result-wrap"></div>
    ) : (
      <>
        <div className={"result-wrap"}></div>

            {create_partner === 1 && (
              <>
               {this.state.showAddPartnerButton ? (
                <Link to={"/partner-register?new_partner"} target="_blank">
                  <div style={{ display: "flex", justifyContent: "right"}}>
                      <button style={styles.addPartnerButton} className="submit">Add Partner</button>
                  </div>
               </Link>
               ) : null}
              </>
             
            )}

            <div className="card">
              {/* <div className="clearfix"> */}
                  <table className="table table-bordered" style={{ tableLayout: 'auto', width: '100%',}}>
                    <thead>
                      <tr>
                        <th style={styles.header}>Partner Details</th>
                        <th style={styles.header}>Status</th>
                       
                        <th style={styles.header}>Source/Sub-Source</th>
                        <th style={styles.header}>Assigned RM</th>
                        <th style={styles.header}>App Logged In</th>
                        <th style={styles.header}>Last App Visit</th>
                        <th style={styles.header}>Latest Platform</th>
                        <th style={styles.header}>Leads Shared</th>
                        <th style={styles.header}>App Inactivity Status</th>
                        <th style={styles.header}>Date</th>
                        <th style={styles.header}>Whatsapp</th>
                        <th style={styles.header}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dealerListData.length === 0 ? (
                        <tr>
                          <td align="center" colSpan="11">
                            <h6 className="text-center text-danger text-bold">
                              {this.props.t("Listing.Search_Result.No_Record_Found")}
                            </h6>
                          </td>
                        </tr>
                      ) : null}
                      {this.state.dealerListData.length !== 0 &&
                        this.state.dealerListData.map((dealerdata, k) => {
                          const inactivityStatus = this.calculateAppInactivityStatus(dealerdata.last_login_date);
                          return (

                          <tr key={k} className="dealerdata_basic_info">
                            <td style={styles.cell}>
                              <div>{dealerdata.agent_code}</div>
                              <div>{dealerdata.first_name}</div>
                              <div>{dealerdata.dealership_contact}</div>
                              <div>{dealerdata.dealership_email}</div>
                            </td>
                            <td style={{ ...styles.cell, width: '10%' }}>
                              <div>{dealerdata.partnerStatus}</div>
                            </td>
                            <td style={styles.cell}>
                              <div>{dealerdata.source}</div>
                              <div>{dealerdata.subsource}</div>
                            </td>
                            <td style={styles.cell}>
                              <div>{dealerdata.sfa_refferal_user_name}</div>
                              <div>{dealerdata.sfa_refferal_user_mobile}</div>
                            </td>
                            <td style={styles.cell}>
                              <div>
                                <div>
                                  <span style={styles.bold}>First: </span>
                                  {dealerdata.first_login_date
                                    ? new Date(dealerdata.first_login_date).toLocaleDateString()
                                    : "NA"}{" "}
                                  {dealerdata.first_login_date
                                    ? new Date(dealerdata.first_login_date).toLocaleTimeString()
                                    : ""}
                                </div>
                                <div>
                                  <span style={styles.bold}>Latest: </span>
                                  {dealerdata.last_login_date
                                    ? new Date(dealerdata.last_login_date).toLocaleDateString()
                                    : "NA"}{" "}
                                  {dealerdata.last_login_date
                                    ? new Date(dealerdata.last_login_date).toLocaleTimeString()
                                    : ""}
                                </div>
                              </div>
                            </td>
                            <td style={styles.cell}>
                              <div>
                                {dealerdata.last_app_visit
                                  ? new Date(dealerdata.last_app_visit).toLocaleDateString()
                                  : "NA"}{" "}
                                {dealerdata.last_app_visit
                                  ? new Date(dealerdata.last_app_visit).toLocaleTimeString()
                                  : ""}
                              </div>
                            </td>
                            <td style={styles.cell}>{dealerdata.latest_platform}</td>
                            <td style={styles.cell}>
                              <div style={styles.flexColumn}>
                              <div onClick={() => this.LeadsData(dealerdata.id)}>
                                <div
                                  style={{
                                    color: dealerdata.total_leads ? "blue" : "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={dealerdata?.total_leads === 0  ? null : this.openModal}>
                                  {dealerdata.total_leads ? dealerdata.total_leads : 0}
                                </div>
                               
                                </div>
                              </div>
                            </td>
                            <td style={styles.cell}>
                             
                                
                            <div>
                        {`days: ${inactivityStatus.days}/hours: ${inactivityStatus.hours}`}
                      </div>
                              
                           
                            </td>
                            <td style={styles.cell}>
                              <div>
                                <span style={styles.bold}>Created: </span>
                                {dealerdata.created_date
                                  ? new Date(dealerdata.created_date).toLocaleDateString()
                                  : "NA"}{" "}
                                {dealerdata.created_date
                                  ? new Date(dealerdata.created_date).toLocaleTimeString()
                                  : ""}
                              </div>
                              <div>
                                <span style={styles.bold}>Updated: </span>
                                {dealerdata.updated_date
                                  ? new Date(dealerdata.updated_date).toLocaleDateString()
                                  : "NA"}{" "}
                                {dealerdata.updated_date
                                  ? new Date(dealerdata.updated_date).toLocaleTimeString()
                                  : ""}
                              </div>
                            </td>

                            {/* Whatsapp Share And Log Buttons Started-----------*/}
                            <td>{this.renderShareAndViewButton(dealerdata)}</td>

         
                              <td style={styles.cell}>
                                <div style={styles.flexCenter}>
                                  {view_history === 1 && (
                                     <div className="mrg-r10" onClick={() => this.showHistory(dealerdata.id)}>
                                     <i
                                       className="ic-Call-history"
                                       style={{ fontSize: 25, cursor: "pointer" }}
                                       onClick={this.openModal1}
                                     ></i>
                                   </div>
                                  )}

                                {MarkAsDemo(dealerdata.id)}

                                  {edit_partner === 1 && 
                                    <div className="mrg-r10">
                                    <Link
                                      to={`/dealer/${dealerdata.dealer_id_hash}/sales-registered`}
                                      // className="btn btn-default"
                                    >
                                      <div className="commoniconbtn">
                                        <i
                                          className="ic-createmode_editedit icons-normal"
                                          style={{
                                            // fontSize: 25,
                                            cursor: "pointer",
                                            // margin: 0,
                                            // padding: 0,
                                          }}
                                        ></i>
                                      </div>
                                    </Link>
                                  </div>
                                  }
                                  
                                </div>
                              </td>
                          </tr>
                        )})}
                    </tbody>
                  </table>
                  {this.state.viewModal ? (
                    <div className="modal-body">
                      <Modal
                        show={this.state.viewModal}
                        handleClose={this.closeModal}
                      >
                        <div className="modal-header"></div>
                        <LeadData LeadsData={this.state.LeadsData} />
                      </Modal>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.viewHistory ? (
                    <div className="">
                      <Modal
                        show={this.state.viewHistory}
                        handleClose={this.hideModalViewTimeLine}
                      >
                        <PartnerHistory
                          partnerId={this.state.partner_id} source = {"sales"}
                        />
                      </Modal>
                    </div>
                  ) : null}

                  {this.state.showWhatsappModal ? (
                    <Modal show={this.state.showWhatsappModal} handleClose={() => this.setState({showWhatsappModal: false})}>
                      <WhatsAppLogTable
                        lead={this.state.currentPartnerData}
                        logs={this.state.whatsappLog}
                        notificationId={40}
                      ></WhatsAppLogTable>
                    </Modal>
                  ) : null}
                </div>
      </>
    );
  }
}

const styles = {
  addPartnerButton: {
    backgroundColor: '#6147FF',
    color: '#fff',
    borderRadius: '10px 0 0 0',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#fff1d6',
    color: 'rgba(0, 0, 0, 0.67)',
    padding: '10px',
    textAlign: 'left',
    fontWeight: 'bold',
    // whiteSpace: 'nowrap',
  },
  cell: {
    padding: '10px',
    textAlign: 'left',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bold: {
    fontWeight: 'bold',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  whatsappContainer: {
    display: "flex",
    cursor: "pointer",
    fontSize: "16px",
    // paddingRight: "40px",
  },
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};
export default withTranslation("dealers")(
  connect(mapStateToProps, mapDispatchToProps)(CallerSearchResult)
);
