import DateFormate from "dateformat";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { DEALER_WALLET_ID } from "../../config/constants";
// import ViewHistory from "../../dealerDetail/component/ViewHistory";
// import ModalPopup from "../../elements/Popup";
import { DealerService } from "../../services/index";
import MasterService from "../../services/MasterService";
import DatePicker from "react-datepicker";
import { Tab, Nav } from "react-bootstrap";
import ModalOne from "../elements/partnerModal";
import Modal from "./component/modal/modal";
import LeadData from "./leadHistory";
import FollowUpHistoryForm from "../leads/bank-details/PartnerFollowUpHistory";
import PartnerHistory from "../lead-list/PartnerHistory";
import { executeGraphQLQueryFinex } from '../../common/executeGraphQLQueryFinex';
import BlackListPopup from "./component/blacklist-popup/BlackListPopup";
import {IS_USER_ACCESS_PAGE} from '../../config/constants';
import { MarkAsDemo } from "./component/mark-as-demo/MarkAsDemo";

// import BlackListPopup from "./BlackListPopup";
// import { de } from "date-fns/locale";
class SearchResult extends Component {
  constructor(props) {
 
    super(props);
    this.state = {
      dealerListData: props.dealerListData,
      leadsCount: props.leadsCount,
      mergeData : props.mergeData,
      // loading: false,
      pageloading: false,
      statusClassname: {
        ready_for_verification: "nav-item active",
        pending: "nav-item",
        active: "nav-item ",
        rejected: "nav-item",
        inactive: "nav-item",
        incomplete: "nav-item",
        blacklist: "nav-item",
        finmissed: "nav-item",
        kycmissed: "nav-item",
        all: "nav-item",
        only_registerd: "nav-item",
        doc_pending: "nav-item",
        all_pending: "nav-item",
        viewModal: false,
        viewHistory:false,
        viewHistoryData : [],
        rowData: [],
        colDefs: [
          { field: "Partner Deatils" },
          { field: "Status" },
          { field: "Source/Sub-SOurce" },
          { field: "Assigned RM" },
          { field: "LggedIN" },,
          { field: "Last App Visit" },,
          { field: "Latest Platform" },
        ]

      },
      filterData: this.props.filterData,
      dealerUpdateData: {},
        blackListPopup: false,
      blackListUpdateData: {},
      currentIndex: null,
      current_org_name: null,
      historyPopup: false,
      histoy_dealer_id: 0,
      openedRowIndex: null,
    };
  }

  componentDidMount = () => {
    try {
      console.log("delearDtaDevev", this.state.dealerListData);
      // console.log("delearDtaDevev123", this.state.leadsCount);
      console.log("mergeData++++" , this.state.mergeData);
    } catch (error) {
      console.log(error);
    }
  }

  showRowIndex = (index) => {
    this.setState(
      (prevState) => ({
        openedRowIndex: prevState.openedRowIndex === index ? null : index,
      }),
      () => {
        const tooltips = document.querySelectorAll(".toggle-checkbox:checked");
        tooltips.forEach((tooltip, i) => {
          if (i !== index) {
            tooltip.checked = false;
          }
        });
      }
    );
  };

  closeHistoryModel = () => {
    this.setState({ historyPopup: false });
  };

  handleHistory = (id) => {
    this.setState({ historyPopup: true });
    this.setState({ histoy_dealer_id: id });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dealerListData !== this.props.dealerListData ||
      nextProps.loading !== this.props.loading ||
      nextProps.leadsCount !== this.props.leadsCount
    ) {
      this.setState({
        dealerListData: nextProps.dealerListData,
        // loading: nextProps.loading,
        pageloading: nextProps.pageloading,
        leadsCount: nextProps.leadsCount
      });
    }
  }
  handleStatusClick = (sname, ovalue) => {
    this.setState({
      // loading: true,
    });
    let filterData = this.state.filterData;
    if (sname == "active") {
      filterData["status"] = 1;
      filterData["list_type"] = "";
    } else if (sname == "inactive") {
      filterData["status"] = 2;
      filterData["list_type"] = "";
    } else if (sname == "incomplete") {
      filterData["status"] = 3;
      filterData["list_type"] = "";
    } else if (sname == "pending") {
      filterData["status"] = 4;
      filterData["list_type"] = "pending";
    } else if (sname == "blacklist") {
      filterData["status"] = 5;
      filterData["list_type"] = "";
    } else if (sname == "all") {
      filterData["status"] = null;
      filterData["list_type"] = "";
    } else if (sname == "finmissed") {
      filterData["status"] = 4;
      filterData["list_type"] = "fin_missed";
    } else if (sname == "rejected") {
      filterData["status"] = 4;
      filterData["list_type"] = "rejected";
    } else if (sname == "kycmissed") {
      filterData["status"] = 4;
      filterData["list_type"] = "kyc_missed";
    } else if (sname == "only_registerd") {
      filterData["status"] = 4;
      filterData["list_type"] = "only_registerd";
    } else if (sname == "ready_for_verification") {
      filterData["status"] = 4;
      filterData["list_type"] = "ready_for_verification";
    } else if (sname == "doc_pending") {
      filterData["status"] = 4;
      filterData["list_type"] = "doc_pending";
    } else if (sname == "all_pending") {
      filterData["status"] = 4;
      filterData["list_type"] = "pending";
    }

    this.setState({ filterData });
    if (typeof this.props.statusClick === "function") {
      this.props.statusClick(sname, filterData["list_type"]);
      this.setState({
        statusClassname: {
          active: "nav-item",
          inactive: "nav-item",
          rejected: "nav-item",
          incomplete: "nav-item",
          blacklist: "nav-item",
          pending: "nav-item",
          finmissed: "nav-item",
          kycmissed: "nav-item",
          all: "nav-item",
          ready_for_verification: "nav-item",
          doc_pending: "nav-item",
          only_registerd: "nav-item",
          all_pending: "nav-item",
          [sname]: "nav-item active",
        },
      });
    }
  };





  getBasicDetails() {
    return new Promise((resolve, reject) => {
      DealerService.getMasters(["dealer_sku"])
        .then((response) => {
          if (response.status == 200 && response.data.status == 200) {
            let responsedata = response.data.data || {};
            if (responsedata.dealer_sku && responsedata.dealer_sku.length) {
              let subscM = [];
              responsedata.dealer_sku.map((v) => {
                if (v.sku_type == "subscription") subscM.push(v);
              });
              resolve(subscM);
            }
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  checkBalance = async (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        let balance = await DealerService.checkBalance(params);
        if (balance && balance.data && balance.data.status === 200) {
          if (
            balance &&
            balance.data &&
            balance.data.data &&
            balance.data.data.closing_balance === 0
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          reject(balance.data.message);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  fetchEditData = (dealer_id_hash) => {
    return new Promise((resolve, reject) => {
      DealerService.getSkusDetails(dealer_id_hash)
        .then((response) => {
          if (response.status == 200 && response.data.status == 200) {
            let editDataAll = response.data.data || [];
            let [row] = editDataAll.filter(
              (item) => item.b_details_sku_id === DEALER_WALLET_ID
            );
            this.getBasicDetails()
              .then((subM) => {
                let [master] = subM.filter(
                  (item) => item.id === DEALER_WALLET_ID
                );
                row.b_details_is_active = "0";
                row.master = master;
                resolve(row);
              })
              .catch((error) => reject(error));
          } else {
            reject(response.data);
          }
        })
        .catch((error) => reject(error));
    });
  };

  handleChange =
    (idx, dealer_id_hash, current_status) =>
    async ({ target }) => {
      let isChecked = target.checked;
      let [dealer_data] = this.state.dealerListData.filter(
        (item) => item.dealer_id_hash === dealer_id_hash
      );
      await this.changeDealerStatus(
        idx,
        target,
        isChecked,
        current_status,
        dealer_id_hash
      );
    };


  updateDealerListData = (updatedObj, index) => {
    let dealerListTempData = [...this.state.dealerListData];
    dealerListTempData[index].status = updatedObj.status;
    this.setState({ dealerListData: dealerListTempData });
  };
  async changeDealerStatus(
    idx,
    target,
    isChecked,
    current_status,
    dealer_id_hash
  ) {

    let org = this.state.dealerListData[idx] || {};
    let active = isChecked ? "Active" : "Inactive";
    var r = window.confirm(
      this.props.t("Listing.Search_Result.alert_active_inactive", {
        status: active,
        dealer_name: org.organization,
      })
    );
    let dealerUpdateData = this.state.dealerUpdateData;
    if (r == true) {
      if (isChecked) {
        dealerUpdateData["status"] = "1";
        dealerUpdateData["current_status"] = current_status;
        dealerUpdateData["dealer_id_hash"] = dealer_id_hash;
      } else {
        dealerUpdateData["status"] = "2";
        dealerUpdateData["current_status"] = current_status;
        dealerUpdateData["dealer_id_hash"] = dealer_id_hash;
      }
      this.setState({ dealerUpdateData });
      var thisObj = this;
      let dealerListTempData = [...this.state.dealerListData];
      // thisObj.setState({ loading: true });
      let checkVal = isChecked;
      const response = await MasterService.post("dealer/dealer/status_change", {
        ...this.state.dealerUpdateData,
      });
      thisObj.flag = false;
      if (response.data.status == 200) {
        if (checkVal) {
          target.parentNode.style.textDecoration = "";
          dealerListTempData[idx].status = 1;
        } else {
          target.parentNode.style.textDecoration = "line-through";
          dealerListTempData[idx].status = 2;
        }
        thisObj.setState({
          dealerListData: dealerListTempData,
          loading: false,
        });
      } else {
        thisObj.setState({ loading: false });
        toast.error(response.data.message);
      }
    }
  }
  ListOfNotUploadedDocuments(dealer_id) {
    let dealerdata = dealer_id;

    let arr = [];
    if (dealerdata.applicant_type === "1") {
      dealerdata.pending_docs.forEach((item) => {
        if (item.dealer_id === null) {
          arr.push(item.name);
        }
      });
      console.log(arr);
      return (
        <ul>
          {arr.map((element) => (
            <li key={element}>{element}</li>
          ))}
        </ul>
      );
    } else if (dealerdata.applicant_type === "2") {
      dealerdata.pending_docs.forEach((item) => {
        if (item.dealer_id === null) {
          arr.push(item.name);
        }
      });
      if (dealerdata.is_gst === null) {
        arr = arr.filter((value) => value !== "GSTN");
      }

      if (dealerdata.is_msme === null) {
        arr.filter((value) => {
          return value !== "MSME Certificate";
        });
      }
      console.log(arr);
      return (
        <ul>
          {arr.map((element) => (
            <li key={element}>{element}</li>
          ))}
        </ul>
      );
    }
  }
  manageleads = () => {
  console.log ("leadsCOunt" ,  this.state.leadsCount)
  return (  
      this.state.leadsCount.map((count) => {
        <td>
          {count.co_applicant.length}
        </td>
      })
    
  )
  }
openModal = () => {
  this.setState({viewModal : true})
}

closeModal = () => {
  this.setState({viewModal : false})
}

showHistory = (id) => {
  document.body.classList.add("overflow-hidden");
  this.setState({viewHistory : true});
  this.setState({ partner_id: id});
};

handleBlackList = (idx, dealer_id_hash, status) => {
  console.log("idx, dealer_id_hash, status-------------------", idx, dealer_id_hash, status);
  let tempBlackListUpdateData = { ...this.state.blackListUpdateData };
  tempBlackListUpdateData.status = status;
  tempBlackListUpdateData.dealer_id_hash = dealer_id_hash;
  let org_name =
    this.state.dealerListData[idx] &&
    this.state.dealerListData[idx].organization;

    console.log("tempBlackListUpdateData");
    console.log("tempBlackListUpdateData123", tempBlackListUpdateData);
  this.setState({
      blackListPopup: true,
      blackListUpdateData: tempBlackListUpdateData,
      currentIndex: idx,
      current_org_name: org_name,
    });
  };


  closeBlackListModal = () => {
    this.setState({ blackListPopup: false });
  };

hideModalViewTimeLine = () => {
  this.setState({ viewHistory: false });
  console.log("falsefalsefalsefalsefalsefalsefalsefalse", this.state.viewHistory);
  document.body.classList.remove("overflow-hidden");
}

calculateAppInactivityStatus(lastLoginDate, lastLoginTime) {

  const lastLoginDateTime = new Date(`${lastLoginDate} ${lastLoginTime}`);


  const timeDifference = new Date() - lastLoginDateTime;


  const daysInactive = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const minutesInactive = Math.floor(timeDifference / (1000 * 60));
  const secondsInactive = Math.floor(timeDifference / 1000);

  return {
      days: daysInactive,
      minutes: minutesInactive,
      seconds: secondsInactive
  };
}
changeAccess = async (data, partner_id, user_id) => {
  const params = {};
  if (data === null || data === 0) {
    params.status = 1; 
  } else {
    params.status = 0;
  }

  try {
    const response = await MasterService.post(`/partner/partner/change-sub-partner-creation-access?partner_id=${partner_id}&user_id=${user_id}&status=${params.status}`);
    console.log ("++++++++++++++++++++++++++++++++++++++++++++++++" , response?.data?.data?.status , response?.data?.data?.status , response)
    if (response?.data?.data?.status === 0 || response?.data?.data?.status === 1 || response?.data?.data?.message !== "")
      {
        toast.success("Data Updated SuccessFully")
      }
      else {
        toast.error("something went wrong")
      }

  } catch (error) {
    console.error("Error changing access:", error);
  }
}




render() {
  const { dealerCountStatusDetail } = this.props;
  // const hideBlackListIcon = window.location.search !== '?type=all&role=admin&role=admin'
  // console.log("window.location.search", hideBlackListIcon);

    let list = IS_USER_ACCESS_PAGE("partner", "list")?.is_edit_access;
    let edit_partner =  IS_USER_ACCESS_PAGE("partner", "update")?.is_edit_access;
    let create_partner =  IS_USER_ACCESS_PAGE("partner", "create")?.is_edit_access;
    let partner_status = IS_USER_ACCESS_PAGE("partner", "partner_status")?.is_edit_access;
    let view_history =  IS_USER_ACCESS_PAGE("partner", "activity_list")?.is_edit_access;
    let partner_action = IS_USER_ACCESS_PAGE("partner", "partner_action")?.is_edit_access;
    let partner_level_restriction = IS_USER_ACCESS_PAGE("partner_level_restriction", "partner_level_restriction")?.is_edit_access;
    return (
      <>
        {
          (this.state.filterData.list_type === "pending" ||
            this.state.filterData.list_type === "ready_for_verification" ||
            this.state.filterData.list_type === "doc_pending" ||
            this.state.filterData.list_type === "all_pending" ||
            this.state.filterData.list_type === "only_registerd") && (
            <div style={styles.tabsContainer}>
              <ul style={styles.tabs}>
                <li
                  style={
                    this.state.filterData.list_type === "ready_for_verification"
                      ? { ...styles.tabsItem, ...styles.active }
                      : styles.tabsItem
                  }
                  onClick={this.handleStatusClick.bind(this, "ready_for_verification")}
                >
                  <div style={styles.tabContent}>
                    Ready For Verification
                    <span style={styles.tabSpan}>
                      ({dealerCountStatusDetail.readyforVerification})
                    </span>
                  </div>
                </li>
                <li
                  style={
                    this.state.filterData.list_type === "doc_pending"
                      ? { ...styles.tabsItem, ...styles.active }
                      : styles.tabsItem
                  }
                  onClick={this.handleStatusClick.bind(this, "doc_pending")}
                >
                  <div style={styles.tabContent}>
                    Docs Pending
                    <span style={styles.tabSpan}>
                      ({dealerCountStatusDetail.docPending})
                    </span>
                  </div>
                </li>
                <li
                  style={
                    this.state.filterData.list_type === "only_registerd"
                      ? { ...styles.tabsItem, ...styles.active }
                      : styles.tabsItem
                  }
                  onClick={this.handleStatusClick.bind(this, "only_registerd")}
                >
                  <div style={styles.tabContent}>
                    Only Registered
                    <span style={styles.tabSpan}>
                      ({dealerCountStatusDetail.onlyRegisterd})
                    </span>
                  </div>
                </li>
                <li
                  style={
                    this.state.filterData.list_type === "pending"
                      ? { ...styles.tabsItem, ...styles.active }
                      : styles.tabsItem
                  }
                  onClick={this.handleStatusClick.bind(this, "all_pending")}
                >
                  <div style={styles.tabContent}>
                    All
                    <span style={styles.tabSpan}>
                      ({dealerCountStatusDetail.pending})
                    </span>
                  </div>
                </li>
              </ul>
              {create_partner === 1 && (
                <Link to={"/dealer"} target="_blank">
                  <div style={{ display: "flex", justifyContent: "right"}}>
                      <button style={styles.addPartnerButton}>Add Partner</button>
                  </div>
               </Link>
              )}
            </div>
          )
        }
         
        <div className="card">
          <div className="clearfix">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr style={{backgroundColor: '#fff1d6'}}>
                    <th>AMB</th>
                    <th>Details</th>
                    {this.state.filterData.list_type ===
                      "doc_pending" && (
                        <th>Docs Status</th>
                      )}
                    <th>Location</th> 
                    <th>Partner Type</th>
                    <th>Source</th>
                    <th>Registration On</th>
                    <th>Referred</th>
                    {partner_action ===1  ? <th>Actions</th> : "" }
                    
                       
                  </tr>
                </thead>
                <tbody>
                  {this.state.dealerListData.length == 0 ? (
                    <tr>
                      <td align="center" colSpan="7">
                        <h6 className="text-center text-danger text-bold">
                          {this.props.t(
                            "No Data Found"
                          )}
                        </h6>
                      </td>
                    </tr>
                  ) : null}
                  {this.state.dealerListData.length != 0 &&
                    this.state.dealerListData.map((dealerdata, k) => (
                      <tr key={k} className="dealerdata_basic_info">
                        <td>
                          <div>
                          {dealerdata.agent_code}
                          </div>
                         
                          
                          
                        </td>

                        <td style={{ width: "10%" }}>
                          
                        <div>
                            {dealerdata.applicant_type_id == "2"
                              ? dealerdata.organization
                              : dealerdata.first_name}
                          </div>
                          <div>{dealerdata.dealership_email}</div>
                          <div>{dealerdata.dealership_contact}</div>

                          {/* <div><img src="https://dealeradmin.gaadi.com/admin/public/images/gaadi.png"/></div> */}
                        </td>



                        {this.state.filterData.list_type ===
                          "all" && (
                        <td>
                          {/* <b>Bussiness</b>: {dealerdata.is_bussiness_approve == "1" ? "Yes" : "Pending"}<br></br> */}
                          {dealerdata.is_aadhar_pan_linked == "1" ? "Yes" : "Pending"}
                        </td>
                         )}


{this.state.filterData.list_type ===
                          "doc_pending" && (
                            <td>
                              <input
                                type="checkbox"
                                className="toggle-checkbox"
                                id={`toggle-tips-${k}`}
                              />


                              <label htmlFor={`toggle-tips-${k}`}>
                                <div
                                  className="offermeternew"
                                  onClick={() => this.showRowIndex(k)}
                                >
                                  <div className="confidencename">
                                    <div className="confidencename">
                                      {(() => {
                                        const filteredPendingDocs =
                                          dealerdata.pending_docs && dealerdata.pending_docs.filter(
                                            (element) => element.dealer_id === null
                                          );
                                        const pendingDocsCount = filteredPendingDocs ? filteredPendingDocs.length : 0;

                                        if (dealerdata.applicant_type === "1") {
                                          return `${pendingDocsCount} pending`;
                                        } else {
                                          const subtract =
                                            dealerdata.applicant_type === "2" &&
                                              (dealerdata.is_gst === null ||
                                                dealerdata.is_msme === null)
                                              ? 1
                                              : 0;
                                          return `${pendingDocsCount - subtract
                                            } pending`;
                                        }
                                      })()}
                                    </div>
                                  </div>
                                  <i className="ic-arrow_drop_down" style={{fontSize:20}}></i>
                                  {/* <img
                                    src={require("../../webroot/images/downarrow.svg")}
                                    alt=""
                                  /> */}
                                  {this.state.openedRowIndex === k && (
                                    <div className="tips-list">
                                      <div>
                                        <ul>
                                          {this.ListOfNotUploadedDocuments(dealerdata)}
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </label>

                            </td>
                          )}


                       <td>
                       {dealerdata.cityname ? dealerdata.cityname : null}
                          <div>
                            {dealerdata.pincode ? dealerdata.pincode : ""}
                          </div>
                       </td>



                        <td>
                        <td>
                          {dealerdata.applicant_type_id == "2"
                            ? "Company"
                            : "Individual"}
                        </td>
                        </td>



                        <td>
                        {dealerdata.source
                            ? dealerdata.source
                            : "Partner App"}
                          </td>
 
                     
           
                        <td>
                        Created :{" "}
                          {dealerdata.created_date
                            ? DateFormate(
                              dealerdata.created_date,
                              "dS mmm yyyy h:MM TT"
                            )
                            : ""}
                          <br></br>
                          <br></br>Update :{" "}
                          {dealerdata.updated_date
                            ? DateFormate(
                              dealerdata.updated_date,
                              "dS mmm yyyy h:MM TT"
                            )
                            : ""}
                        </td>

                 
                        <td>
                        {dealerdata.sfa_refferal_user_name ? (
                            <div>
                              <span> {dealerdata.sfa_refferal_user_name} </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {dealerdata.sfa_refferal_user_name ? (
                            <div>{dealerdata.sfa_refferal_user_mobile}</div>
                          ) : (
                            ""
                          )}
                       </td>
                       {/* <td>
                         
                         {
                          this.state.leadsCount.map ((count , k) => {
                             return (
                              <span key={k}>
                             {count.co_applicant.length}
                             
                              </span>
                              )

                          })
                         }
                            <button onClick={this.openModal}>View Leads</button>
                                 
                                  </td> */}
                                  {/* {this.state.viewModal ? (
                <div className="modal-body">
                    <Modal show={this.state.viewModal} handleClose={this.closeModal} >
                        <div className="modal-header">
                            
                        </div>
                        <LeadData leadsData = {this.state.leadsCount}  />
                    </Modal>
                </div>
            ) : ''} */}
           
{/*                    
                       <td>
                          <div>
                          {
                            dealerdata.created_date ? new Date(dealerdata.created_date).toLocaleDateString() : 'NA'
                          }
                         </div>
                         <div>
                          {
                            dealerdata.created_date ? new Date(dealerdata.created_date).toLocaleTimeString() : 'NA'
                          }
                         </div>
                        </td> */}
                        {partner_action === 1?  <td>
                         

                        <div style={{display:"flex"}}>


                        <div  className="mrg-r10" onClick={() => this.changeAccess(dealerdata.sub_partner_creation_access , dealerdata.id , dealerdata.user_id)}>
                          {partner_level_restriction === 1 && ( dealerdata.sub_partner_creation_access == 1 ?  <div title="deny access" className="commoniconbtn" >
                               <i className="ic-check"></i>
                          </div> :
                           <div title="allow access" className="commoniconbtn">
                           <i className="ic-cancel"></i>
                      </div>)}

                          </div>


                          {view_history === 1 || view_history === 0 ? 
                           <div className="mrg-r10" onClick={() => this.showHistory(dealerdata.id)} >
                           <div className="commoniconbtn"><i className="ic-Call-history icons-normal"></i></div>
                       </div>
                          : ""}
                       
                        {partner_status === 1? 
                        <>
                        {dealerdata.status === "1" || dealerdata.status === "4" || dealerdata.status === '2' ? (
                          <div 
                            className="mrg-r10"
                            onClick={() => this.handleBlackList(
                              k,
                              dealerdata.dealer_id_hash,
                              "2"
                            )}
                            title="Mark as InActive">
                              <div className="commoniconbtn"><i className="ic-peson-blacklist icons-normal"></i></div>
                          </div>
                        ) : null}
                        </>
                         : ""}

                         {MarkAsDemo(dealerdata.id)}

                         {edit_partner === 1? 
                         <div className="mrg-r10" >
                         <Link style={{color:"gray"}} className="commoniconbtn"
                               to={`/dealer/${dealerdata.dealer_id_hash}/verification`}>
                           <i className="ic-createmode_editedit icons-normal"></i>
                         </Link> 
                       </div>
                         : ""}
                       
                        <div>
                        

                        </div>
                        </div>
                        </td>: ""}
                     




                        {this.state.blackListPopup && (
                            <Modal
                              isOpen={this.state.blackListPopup} togglePopup={() => this.closeBlackListModal(false)}
                              // show={this.state.blackListPopup}
                              title={
                                parseInt(this.state.blackListUpdateData.status) == 5
                                  ? this.props.t("Listing.Search_Result.Black_List_Dealer")
                                  : this.props.t("Listing.Search_Result.active_inactive_Dealer")
                              }
                              // handleClose={this.closeBlackListModal}
                            >
                              <BlackListPopup
                                modalClose={this.closeBlackListModal}
                                blackListUpdateData={dealerdata.status === '2' ? {...this.state.blackListUpdateData, status: '4' } : this.state.blackListUpdateData}
                                updateDealerListData={this.updateDealerListData}
                                currentIndex={this.state.currentIndex}
                                current_org_name={this.state.current_org_name}
                                status={dealerdata?.status}
                                kyc_status={dealerdata?.kyc_status}
                              />
                            </Modal>
                          )}

                      </tr>
                    ))}
                    
                  {this.state.loading ? (
                    <tr>
                      <td className="loading" colSpan="6"></td>
                    </tr>
                  ) : null}
                        
                </tbody>
              </table>
            </div>
          </div>
          {this.renderHistory()}
        </div>
      
     
    
      </>
    );
  }

  renderHistory() {
    return(
      <>
      {this.state.viewHistory ? (
        <div className="">
          <ModalOne
            show={this.state.viewHistory}
            handleClose={this.hideModalViewTimeLine}
            >
            <PartnerHistory partnerId={this.state.partner_id}/>
          </ModalOne>
        </div>
      ) : null}
      </>
    )
  }
}


const styles = {
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between',
  },
  tabs: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    // backgroundColor: '#EAEAFC',
    // borderRadius: '0px 20px 0 0',
  },
  tabsItem: {
    margin: 0,
    padding: '10px 20px',
    cursor: 'pointer',
    // border: '0px solid transparent',
    borderBottom: 'none',
    // transition: 'background-color 0.3s ease, border 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    // borderBottom:"2px solid #6147FF"
    // borderRadius: '0px 20px 0 0',
  },
  active: {
    // backgroundColor: 'white',
    fontWeight: 'bold',
    color:"#6147FF",
    borderBottom:"2px solid #6147FF"
  },
  tabContent: {
    display: 'flex',
    alignItems: 'center',
  },
  tabSpan: {
    marginLeft: '5px',
    fontSize: '0.9em',
    // color: '#666',
    // color:"#6147FF",
    active:{
      color:"#6147FF",
    }
  },
  addPartnerButton: {
    backgroundColor: '#6147FF',
    color: '#fff',
    borderRadius: '10px 10px 0 0',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};


const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};
export default withTranslation("dealers")(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
