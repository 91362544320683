import React, { useEffect, useState } from "react";
import Select from "react-select";
import morefilters from "../../../../webroot/images/morefiltersicon.png";
import sfaService from "../../../../services/sfaService";
import { MasterService } from "../../../../services";
import InputField from "../../../elements/InputField";
import { convertJsonToCsv, downloadFile } from "../../../../helpers/helpers";
import { IS_USER_ACCESS_PAGE } from "../../../../config/constants";
import { toast } from "react-toastify";

const CATEGORY_OPTIONS = [
  { id: "new", label: "New", value: "new" },
  { id: "active", label: "Active", value: "active" },
  { id: "super_active", label: "Super Active", value: "super_active" },
  { id: "churn", label: "Churn", value: "churn" },
  { id: "irregular", label: "Irregular", value: "irregular" },
  { id: "inactive", label: "Inactive", value: "inactive" },
];

const Verification = [
  {
    id: "ready_for_verification",
    label: "Ready For Verification",
    value: "ready_for_verification",
  },
  { id: "doc_pending", label: "Doc Pending", value: "doc_pending" },
  { id: "only_registerd", label: "Only Registered", value: "only_registerd" },
];

const FOLLOW_UP_OPTIONS = [
  { id: "overdue", label: "Overdue", value: "overdue" },
  { id: "today", label: "Today", value: "today" },
  { id: "upcoming", label: "Upcoming", value: "upcoming" },
  { id: "unattempted", label: "Unattempted", value: "unattempted" },
];

const SupportSearchFilter = ({ handleSearchFilters, handleButtonClick }) => {
  const [rmList, setRmList] = useState([]);
  const [callStatusList, setCallStatusList] = useState([]);
  const [searchByList, setSearchByList] = useState([]);
  const [subStatus, setSubStatus] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    handleSearchFilters(filterData);
  }, [filterData, handleSearchFilters]);

  const getRmList = async () => {
    const requestData = { role_id: 3 };
    const rmData = await sfaService.getRMList(requestData);
    setRmList(rmData || []);
  };

  const getStatusList = async () => {
    try {
      const response = await MasterService.get("/partner/partner-call/status");
      const newObj = {
        status_id: 3, status_name: "Unattempted"
      };
      const finalResponse = [...response.data?.data, newObj];
      setCallStatusList(finalResponse);
      
    } catch (error) {
      console.log(error.message);
    }
  };

  const getSearchByList = async () => {
    const response = await MasterService.get("dealer/dealer/search-by-list");
    if (response.data?.data) {
      const updatedData = response.data.data.map((item) =>
        item.label === "Contact no" ? { ...item, label: "Mobile Number" } : item
      );
      setSearchByList(updatedData || []);
    }
  };

  const getDealerList = async () => {
    let userType = "";
    const user_information = JSON.parse(
      localStorage.getItem("user_information")
    );
    if (user_information.role_names.includes("Partner Support Executive")) {
      userType = "partner_support_executive";
    } else if (user_information.role_names.includes("Partner Admin")) {
      userType = "partner_admin";
    }
    const filters = {
      user_type: userType,
      page_no: 1,
      list_type: "saathi_support",
      call_by_source: "sangam",
      user_id: user_information?.id,
      is_export: true,
    };
    const url = "dealer/dealer/dealer-list";
    const response = await MasterService.post(url, filters);
    if (response.data.data) {
      return response.data.data || [];
    }
    return [];
  };

  useEffect(() => {
    getStatusList();
    getRmList();
    getSearchByList();
  }, []);

  const getSubStatusList = async (statusId) => {
    try {
      const response = await MasterService.get(
        `/partner/partner-call/sub-status?status_id=${statusId}&source=support`
      );
      setSubStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching sub-status list:", error);
      toast.error("Error fetching sub-status list");
    }
  };

  const handleSelectChange = (selectedOption, name) => {
    if (name === "call_status") {
      getSubStatusList(selectedOption?.status_id);
      setFilterData((prev) => ({
        ...prev,
        [name]: selectedOption?.status_id || null,
      }));
    } else if (name === "call_sub_status") {
      setFilterData((prev) => ({
        ...prev,
        [name]: selectedOption?.sub_status_id || null,
      }));
    } else if (name === "assigned_bm_id") {
      setFilterData((prev) => ({
        ...prev,
        [name]: selectedOption?.id || null,
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        [name]: selectedOption?.value || null,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFilter = () => {
    handleButtonClick();
  };

  const getDateAndTimeStr = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };

  const handleExportDump = async () => {
    const dealers = await getDealerList();
    const requiredData = dealers.map((obj) => {
      return {
        partner_id: obj.id,
        partner_code: obj.agent_code,
        partner_name: obj.organization,
        dealership_contact: obj.dealership_contact,
        dealership_email: obj.dealership_email,
        partner_status: obj.partnerStatus,
        followup_date: obj.followup_date,
        status_name: obj.status_name,
        sub_status_name: obj.sub_status_name,
      };
    });
    const csvData = convertJsonToCsv(requiredData);
    downloadFile(csvData, "text/csv", `dump_${getDateAndTimeStr()}.csv`);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "white"
        : state.isFocused
        ? "#2684ff"
        : "white",
      color: "black",
    }),
  };

  let export_button = IS_USER_ACCESS_PAGE(
    "partner_export_button",
    "partner_export_button"
  )?.is_edit_access;

  return (
    <>
      <div className="top-heading">
        <div className="filterflex">
          <h2 className="top-heading"></h2>
          <div className="filterinput">
            <div>
              <fieldset className="single-select">
                <div className="material">
                  <Select
                    styles={customStyles}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "search_by")
                    }
                    options={searchByList}
                    name="search_by"
                    placeholder={"Search By"}
                    value={searchByList.filter(
                      ({ value }) => value === filterData.search_by
                    )}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                  />
                </div>
              </fieldset>
            </div>

            <div>
              <fieldset className="search-bx">
                <div className="material">
                  <input
                    id="search_text"
                    type="text"
                    name="search_text"
                    onChange={handleInputChange}
                    className="form-input-new"
                    title={"SEARCH_TEXT"}
                    placeholder="Enter Keyword"
                  />
                  <i className="ic-search"></i>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <div className="search-wrap">
        <div className="row">
          <div className="col-sm-2" style={{ marginBottom: 20 }}>
            <label>Category</label>
            <Select
              id="category"
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "category")
              }
              options={CATEGORY_OPTIONS}
              name="category"
              placeholder="Category"
              value={CATEGORY_OPTIONS.find(
                (opt) => opt.value === filterData.category
              )}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ id }) => id}
            />
          </div>

          <div className="col-sm-2">
            <label>Verification status</label>
            <Select
              id="verification_status"
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "list_type")
              }
              options={Verification}
              name="verification_status"
              placeholder="Verification Status"
              value={Verification.find(
                (opt) => opt.value === filterData.verification_status
              )}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ id }) => id}
            />
          </div>
          <div className="col-sm-2">
            <label>Call Status</label>
            <Select
              id="call_status"
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "call_status")
              }
              options={callStatusList}
              name="call_status"
              placeholder="Call Status"
              value={callStatusList.find(
                (opt) => opt.status_id === filterData.call_status
              )}
              getOptionLabel={({ status_name }) => status_name}
              getOptionValue={({ status_id }) => status_id}
            />
          </div>
          <div className="col-sm-2">
            <label>Disposition</label>
            <Select
              id="call_sub_status"
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "call_sub_status")
              }
              options={subStatus}
              name="call_sub_status"
              placeholder="Disposition"
              value={subStatus.find(
                ({ status_id }) => status_id === filterData.call_sub_status
              )}
              getOptionLabel={({ sub_status_name }) => sub_status_name}
              getOptionValue={({ sub_status_id }) => sub_status_id}
            />
          </div>
          <div className="col-sm-2">
            <label>Followup Category</label>
            <Select
              id="followup_category"
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "followup_category")
              }
              options={FOLLOW_UP_OPTIONS}
              name="followup_category"
              placeholder="Followup Category"
              value={subStatus.find(
                ({ value }) => value === filterData.followup_category
              )}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </div>
          <div className="col-sm-2" style={{ marginTop: 20 }}>
            <div className="more-filter">
              <label className="morefilterbtn" htmlFor="toggleCheckbox">
                More filter
                <img
                  src={morefilters}
                  style={{ marginLeft: 5 }}
                  alt="icon"
                  width={20}
                  height={20}
                />
              </label>
              <input type="checkbox" id="toggleCheckbox" />
              <div id="filterCard">
                <div className="row">
                  <div className="col-sm-6">
                    <label>From Follow-Up Date</label>
                    <div>
                      <InputField
                        inputProps={{
                          id: "from_follow_up_date",
                          type: "date",
                          name: "from_follow_up_date",
                          autoComplete: "off",
                        }}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label>To Follow-Up Date</label>
                    <div>
                      <InputField
                        inputProps={{
                          id: "to_follow_up_date",
                          type: "date",
                          name: "to_follow_up_date",
                          autoComplete: "off",
                        }}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label>From Created Date</label>
                    <div>
                      <InputField
                        inputProps={{
                          id: "from_date",
                          type: "date",
                          name: "from_date",
                          autoComplete: "off",
                        }}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <label>To Created Date</label>
                    <div>
                      <InputField
                        inputProps={{
                          id: "to_date",
                          type: "date",
                          name: "to_date",
                          autoComplete: "off",
                        }}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <InputField
                      inputProps={{
                        id: "dealer_updated_date_from",
                        type: "date",
                        name: "dealer_updated_date_from",
                        autoComplete: "off",
                        label: "From Updated Date",
                      }}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-sm-6">
                    <InputField
                      inputProps={{
                        id: "dealer_updated_date_to",
                        type: "date",
                        name: "dealer_updated_date_to",
                        autoComplete: "off",
                        label: "To Updated Date",
                      }}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-sm-12">
                    <label>RM list</label>
                    <Select
                      id="rm"
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "assigned_bm_id")
                      }
                      options={rmList}
                      name="rm"
                      placeholder="RM"
                      value={rmList.find((opt) => opt.id === filterData.rm)}
                      getOptionLabel={({ profession_name }) => profession_name}
                      getOptionValue={({ id }) => id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="supportbtnflex pull-right">
              <button className="supportapplybtn" onClick={handleFilter}>
                Apply
              </button>
              <button
                className="supportresetbtn"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Clear
              </button>
            </div>
          </div>
          {export_button === 1 ? (
            <div className="exportdumpbtn">
              <button className="supportapplybtn " onClick={handleExportDump}>
                <i className="ic-upload" style={{ fontSize: "24px" }}></i>Export
                Dump
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default SupportSearchFilter;
