import React, { useEffect, useState } from "react";
import DateFormate from "dateformat";
import Pagination from "../elements/pagination.js";
import { toast } from "react-toastify";
import { executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery.js";

import { UPDATE_MATCHED_STATUS } from "../../services/payout.gql.js";
import moment from "moment";
import CheckBox from "../elements/Radiobox.js";
import { PAYOUT_STATUS_LIST } from "../../config/constants.js";

const PreEligibleTableContent = ({
  partnerListing,
  eligiblepaginationdata,
  onPaginate,
  page_no,
  handleChange,
  productType,
  financers
}) => {
  const [checkedBox, setCheckedBox] = useState([])

  useEffect(()=>{
    handleChange(checkedBox)
  },[checkedBox])

  const handleCheck = (event) => {
    const { value, checked } = event.target;
    setCheckedBox((prevItems) => {
      if (checked) {
        return [...prevItems, parseInt(value)];
      } else {
        return prevItems.filter((item) => item !== parseInt(value));
      }
    });    
  };

  // console.log({checkedBox})
  // console.log(partnerListing)

  return (
    <>
      <div className="clearfix">
        <div className="table-responsive">
          <table className="table table-bordered tablefontsize">
            <thead>
              <tr>
                <th>Partner Details</th>
                <th>Disb.Details</th>
                <th>Disb.Amount</th>
                <th>Disb.Date</th>
                <th>Reason</th>
                <th>Payout Amount</th>
                {/* <th>Payout Points</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {partnerListing &&
                partnerListing.map((partner, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Lead ID : <span> {partner.lead_id}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Partner Code : <span> AMK{partner.partner_id}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Customer Name : <span> {partner.customer_name}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Partner Name : <span>{partner.partner_name}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Fulfillment Type :{" "}
                          <span> {partner.fulfillment_type}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          {/* Product : <span> {productType.filter(product => product.id==partner.product)[0]?.name}</span> */}
                          Product : <span> {partner.product}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          {/* Bank : <span> {financers.filter(bank => bank.id==partner.financer_id)[0]?.name}</span> */}
                          Bank : <span> {partner.bank}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>
                            {new Intl.NumberFormat("en-IN").format(
                              partner.total_disbursal_amount
                            )}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>
                            {partner.disbursal_date
                              ? DateFormate(
                                  new Date(partner.disbursal_date),
                                  "dS mmm yyyy"
                                )
                              : null}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>{PAYOUT_STATUS_LIST[partner.payout_status]}</span><br></br>
                         {partner.points ?  <span>({partner.points})</span> : null }
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>
                            {new Intl.NumberFormat("en-IN").format(
                              partner.total_payout_amount
                            )}
                          </span>
                        </div>
                      </td>
                      {/* <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>{partner.points}</span>
                        </div>
                      </td> */}
                      <td>
                        <div className="col-sm-3">
                          <CheckBox
                            type="checkbox"
                            name="checkall[]"
                            id={`recalc_${partner.payout_partner_id}`}
                            key={`recalc_${partner.payout_partner_id}`}
                            value={partner.payout_partner_id}
                            checked={(partner?.payout_partner_id ? checkedBox.includes(partner.payout_partner_id) : false)}
                            onChange={(e)=> handleCheck(e)}
                          />
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              {!partnerListing ||
                (partnerListing.length === 0 && (
                  <tr>
                    <td colSpan={3}>No payout found!</td>
                  </tr>
                ))}
            </tbody>
            <tr>
              <td colSpan={3}>
                {" "}
                <Pagination
                  pageCount={
                    eligiblepaginationdata && eligiblepaginationdata.totalpage
                      ? eligiblepaginationdata.totalpage
                      : 0
                  }
                  activePage={page_no ? page_no : 1}
                  onPaginate={onPaginate}
                />
              </td>
              <td>
                Total:{" "}
                {eligiblepaginationdata && eligiblepaginationdata.totalrecords
                  ? eligiblepaginationdata.totalrecords
                  : 0}{" "}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default PreEligibleTableContent;
