import { gql } from "@apollo/client";

export const PAYOUT__LIST_WITH_FILTERS = gql`
  query getDisbursalLeads(
    $filter_params: LeadsPayoutFiltersArgs!
    $page_no: Float!
  ) {
    getDisbursalLeads(filter_params: $filter_params, page_no: $page_no) {
      disbursals {
        id
        lead_id
        app_id
        tranche_id
        customer_name
        disbursal_date
        disbursal_amount
        partner_id
        paid_status
        payout_paid
        payout_pending
        payout_total
        pat_total
        tds_total
        product_type
        financer_id
        fulfillment_type
        payouts {
          id
          scheme_id
          payout_type
          payout_per
          payout_amount
          tds
          tds_per
          pat
          paid_status
          paid_per
          created_at
          created_by
          updated_by
          comment
          scheme {
            scheme_name
          }
        }
      }
      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
      }
      total_count
    }
  }
`;

export const ADD_PAYOUT = gql`
  mutation savePayoutData($addPayoutArgs: AddPayoutArgs!) {
    savePayoutData(addPayoutArgs: $addPayoutArgs) {
      id
      app_id
    }
  }
`;

export const SEND_FOR_PAYMENT_APPROVAL = gql`
  mutation savePayoutsForPaymentApproval($paymentArgs: AddPaymentArgs!) {
    savePayoutsForPaymentApproval(addPaymentArgs: $paymentArgs)
  }
`;

export const SEND_FOR_PAYMENT_PENDING = gql`
  mutation payment_pending($paymentArgs: AddPaymentArgs!) {
    payment_pending(addPaymentArgs: $paymentArgs)
  }
`;

export const SEND_FOR_PAYMENT_REJECTED = gql`
  mutation payment_rejected($paymentArgs: AddPaymentArgs!) {
    payment_rejected(addPaymentArgs: $paymentArgs)
  }
`;

export const MARKED_AS_PAID = gql`
  mutation payment_paid($paymentArgs: PaymentPaidArgs!) {
    payment_paid(addPaymentArgs: $paymentArgs)
  }
`;

export const PAYOUT_LEADS_COUNTS = gql`
  query {
    leads_count {
      status
      counts
    }
  }
`;

export const PAYOUT_CALCULATION_LAST_MONTH = gql`
  query {
    calculate_base_payout_last_month
  }
`;

export const PAYOUT_CALCULATION_CURRENT_MONTH = gql`
  query {
    calculate_base_payout_current_month
  }
`;

export const PAYOUT_LEADS_EXPORT = gql`
  query payouts_export($filter_params: LeadsPayoutFiltersArgs!) {
    payouts_export(filter_params: $filter_params)
  }
`;
export const FILE_URL_UPLOAD = gql `

  mutation upload_payment($uploadPaymentArgs: UploadPaymentArgs!) { 
    upload_payment(uploadPaymentArgs: $uploadPaymentArgs)
 }
`;

export const FILE_JSON_URL_UPLOAD = gql `
  mutation upload_gst($uploadGstArgs: UploadGstArgs!) { 
    upload_gst(uploadGstArgs: $uploadGstArgs)
 }
 `
;

export const FILE_GST_URL_UPLOAD = gql `
    mutation upload_gst_paid($uploadPaymentArgs: UploadPaymentArgs!) {
    upload_gst_paid(uploadPaymentArgs: $uploadPaymentArgs)
    
    }
`;

export const GET_PAYOUT_POINTS_WITH_FILTERS  = gql`
query payout_points_list($filter_params:PayoutPointsFilterArgs!){
  payout_points_list(filter_params:$filter_params){
    data{
        id
        financier_id
        payout_per
        payoutpointgroup {
          points{
              booking_point
          }          
        }
        start_date
        end_date
        status
        product_type
      }
      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
        page_no
      }
  }
 }

`;

export const SAVE_PAYOUT_POINT = gql`
  mutation save_payou($addPayoutpointsArgs: AddPayoutPointsArgs!) {
    save_payout_points(addPayoutpointsArgs: $addPayoutpointsArgs) {
      status
      msg
      data {
        id
        financier_id
        start_date
        end_date
        payout_per
        status
      }
    }
  }
`;

export const DELETE_PAYOUT_POINT = (id) => gql`
{
  delete_payoutpoint(id:${id})
}`;

export const DETAILS_PAYOUT_POINT = (id) => gql`
{
  payoutpoint_details(id:${id}){
       id
       financier_id
       payout_per
       point_id
       
       start_date
       end_date
       status
  }
}
 `;
export const PARTNER_DISBURSAL_LIST = (page_no) => gql`
  query partner_disbursals($filter_params: LeadsPayoutFiltersArgs!) {
    partner_disbursals(filter_params: $filter_params, page_no: ${page_no}) {
      disbursals {
        partner_id
        partner_name
        partner_company
        partner_city
        partner_status
        total_disbursal_amount
        total_payout_amount
        total_pat
        total_tds
        total_payout_per
        pending_payout
        paid_payout
      }
      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
      }
      total_count
    }
  }
`;
export const INVOICE_LISTING_DATA = (invoice_id)=> gql`
  {  invoice_payouts(invoice_id:${invoice_id}, page_no:1){
     id
     invoice_id
     payout_amount
     tds
     tds_per
     pat
     created_by
     created_at
     payouts{
       approved_at
       approved_by
       payout_per
       paid_per
       scheme{
         scheme_name
         id
       }
       partnerleads{
         lead_id
         tranche_id
         app_id
         disbursal_date
         disbursal_amount
         customer_name
         product_type
         financer_id
         
       }
     }
   }
  }
`;
export const CREATE_INVOICE = gql`
  mutation create_invoice($createinvoiceargs: CreateInvoiceArgs!) {
    create_invoice(createinvoiceargs: $createinvoiceargs)
  }
`;

export const INVOICE_LISTING = (page_no)=> gql`
  query invoice_list($filter_params: LeadsPayoutFiltersArgs!) {
    invoice_list(filter_params: $filter_params, page_no: ${page_no}) {
      invoices {
        id
        invoice_no
        partner_id
        partner_name
        payout_amount
        paid_status
        tds
        tds_per
        pat
        partner_status
        partner_city
        utr_no
        total_lead
        created_by
        created_at
        approved_by
        approved_at
        pending_invoice_url
        approved_invoice_url
        cgst_amount
        igst_amount
        sgst_amount
        paid_date
        gst_utr_no
        gst_paid_date
        gst_number
        paid_updated_by
        bankdetails {
          beneficiary_name
          bank_account_no
          upi_id
          bank_id
          bank_ifsc_code
        }
      }

      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
      }
      total_count
    }
  }
`;

export const EXPORT_CSV_PENDING_PAYMENT = gql`
{
   export_payment_pending_invoice
  }
  `;

export const EXPORT_CSV_ELIGIBLE = gql`
 {
  export_pending_invoice
  }
`;


export const GET_GST_MATCHED_LIST = gql`
query gst_matching_list($filter_params: GstMatchingFiltersArgs!, $page_no: Float!){
    gst_matching_list(filter_params: $filter_params, page_no: $page_no) {
      list{
        id
        invoice_id
        invoice_amt
        rev
        itcavl
        diffprcnt
        irn
        irngendate
        inum
        rsn
        inv_date
        pos
        srctyp
        sgst
        cgst
        igst
        rt
        num
        txval
        trdnm
        supfildt
        supprd
        ctin
        partner_id
        partner_name
        mapping_status
        mapped_invoice_id
        }
        pagination {
        page_no
        prevpage
        totalpage
        totalrecords
        nextpage
        __typename
      }
      __typename
      }
  }
  `;
  
export const EXPORT_CSV_GST_PENDING = gql`
  {
  export_gst_pending_invoice
  }
`;
export const GET_USER_LIST_PAYOUT =  gql`
query{
  get_user_list {
    name 
    user_id
     }
}
`;

export const GST_INVOICE_LISTING = (page_no)=> gql`
  query gst_pending_list($filter_params: LeadsPayoutFiltersArgs!) {
    gst_pending_list(filter_params: $filter_params, page_no: ${page_no}) {
      invoices {
        id
        invoice_no
        partner_id
        partner_name
        payout_amount
        paid_status
        tds
        tds_per
        pat
        partner_status
        partner_city
        total_lead
        created_by
        created_at
        approved_by
        approved_at
        pending_invoice_url
        approved_invoice_url
        cgst_amount
        igst_amount
        sgst_amount
        is_gst_calc
        is_itc
        is_claim_date
        is_reverse_charge
        sgst
        cgst
        igst
        paid_date
        gst_number
        bankdetails {
          beneficiary_name
          bank_account_no
          upi_id
          bank_id
          bank_ifsc_code
        }
      }

      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
      }
      total_count
    }
  }
`;

export const UPDATE_MATCHED_STATUS = (partner_id,id) => gql`
{
  update_matched_status(partner_id:${partner_id},id:${id})
}`;

export const PRE_ELIGIBLE_PARTNER_LIST = (page_no) => gql`
  query pre_eligible_partners($filter_params: LeadsPayoutFiltersArgs!) {
    pre_eligible_partners(filter_params: $filter_params, page_no: ${page_no}) {
      disbursals {
        payout_partner_id
        lead_id
        partner_id
        partner_name
        customer_name
        product
        bank
        total_disbursal_amount
        disbursal_date
        payout_status
        total_payout_amount
        points
      }
      pagination {
        totalpage
        totalrecords
        nextpage
        prevpage
      }
      total_count
    }
  }
`;

export const EXPORT_CSV_PRE_ELIGIBLE = gql`
 {
  export_pre_eligible_partners
  }
`;

export const RE_CALCULATE_PRE_ELIGIBLE_LEADS = gql`
  mutation re_calculate_pre_eligible_leads($PreEligibleLeadArgs: PreEligibleLeadArgs!) {
    re_calculate_pre_eligible_leads(PreEligibleLeadArgs: $PreEligibleLeadArgs)
  }
`;
