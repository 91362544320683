import { gql } from "@apollo/client";

export const BANK_ONBOARDING_PAYIN_LIST = (page_number) =>  gql`
  query payin_config_list($filter_params: ConfigPayinFiltersArgs!) {
    payin_config_list(filter_params: $filter_params, page_no: ${page_number}) {
      payinlist {
        financer_id
        bank_name
        is_on_payin
        is_partnership
        logo
        total_payout
      }
      pagination {
      totalpage
      totalrecords
      nextpage
      prevpage
      __typename
    }
    total_count
    productData {
        product_type
        bank_id
        dsa_code
    }
    }
  }
`;


export const UPDATE_BANK_ONBOARDING_PAYIN = gql`
  mutation save_on_payin($addpayinconfigargs: AddPayinConfigArgs!) {
    save_on_payin(addpayinconfigargs: $addpayinconfigargs)
  }
`;

export const MIS_LIST = (page_number) => gql`
  query mis_list($filter_params: LeadMisFiltersArgs!) {
    mis_list(filter_params: $filter_params, page_no: ${page_number}) {
      list {
        id
        lead_id
        tranche_id
        bank_id
        loan_type
        disbursed_amount
        monthly_cycle_payin
        payin_base_per
        base_payin_amount
        booster_payin_per
        booster_payin_amount
        total_amount
        booster_payin_confirmed
        confirmed_amount
        comments
        created_at
        is_calculated
      }
      pagination {
        page_no
        prevpage
        totalpage
        totalrecords
        nextpage
      }
    }
  }
`;

export const UPLOAD_MIS_LIST = gql`
  mutation mis_upload($uploadPaymentArgs: UploadMisArgs!) {
    mis_upload(uploadPaymentArgs: $uploadPaymentArgs)
  }
`;
